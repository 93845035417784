import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider, useDispatch } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
/******** imported ***********/
import Routs from "./routes";
import store from "./store";


function App() {
  // console.log("app")
  // if (!process.env.REACT_APP_APPNAME) {
  // console.log = function () { };  // Override console.log to a no-op function
  // }
  console.log = function () { };

  return (
    <Provider store={store}>
      <ToastContainer
        autoClose={4000}
        limit={1}
      />
      <BrowserRouter>
        <Routs />
      </BrowserRouter>
    </Provider>
  );
}

export default App;

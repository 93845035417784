import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { USER } from "common/viewContent";
import * as Path from "routes/Path/index";
import { gameLeaderboardListApi, updateLeaderboardApi } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import UpdateLeaderboardModal from "components/Modals/UpdateLeaderboard";
import FormikForm from "components/shared/FormikForm";
import moment from "moment-timezone";
import { checkPermissions } from "helpers/common";
import { generateWeekOptions } from "constants";


const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
  {
    name: "weekNo",
    type: "select",
    label: "",
    placeholder: "Select Week...",
    options: generateWeekOptions("Etc/GMT-4")
  }
];
const initialValuesForFilter = {
  search: "",
  weekNo: moment().tz("Etc/GMT-4").week()
};

const List = () => {
  const dispatch = useDispatch();
  const { gameLeaderboardList, loading, totalLeaderboard } = useSelector(({ Game }) => Game);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [showUpdatePop, setShowUpdatePop] = useState({
    show: false,
    rowData: null
  });
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    status: "",
    weekNo: moment().week()
  });
  const { user } = useSelector((state) => state.Auth);
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false
  })

  useEffect(() => {
    if (user?.permissions) {
      setPermissions({
        add: false,
        edit: checkPermissions(["edit_leaderboard_points"], user?.permissions),
        delete: false,
      })
    }
  }, [user]);

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ handleEditHandler, permissions })?.filter((item) => {
      if (item?.text === "Action" && (!permissions.edit)) {
        return false;
      }
      return true;
    }));
  }, [filter, permissions]);

  async function getApiData(query) {
    try {
      dispatch(gameLeaderboardListApi({ data: query }));
    } catch (error) { }
  }

  const handleEditHandler = (info) => {
    setShowUpdatePop({
      show: true,
      rowData: info
    })
  };

  const handleConfirmUpdate = (values) => {
    console.log("Values", values);
    if (showUpdatePop.rowData) {
      const payload = { _id: showUpdatePop?.rowData?._id, points: values?.points || 0 }
      dispatch(updateLeaderboardApi({
        data: payload, callBack: (err, response) => {
          if (err) return
          getApiData(filter);
          setShowUpdatePop({ show: false, rowData: null });
        }
      }))
    }
  }

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      status: info.status,
      weekNo: info.weekNo
    });
  };

  return (
    <>

      <Helmet title={"Game leaderboard"} />
      {showUpdatePop.show &&
        <UpdateLeaderboardModal
          show={showUpdatePop.show}
          title={"Update Leaderboard Points"}
          loading={loading}
          handleClose={() => setShowUpdatePop({ show: false, rowData: null })}
          handleConfirm={handleConfirmUpdate}
          initialValues={showUpdatePop?.rowData ? showUpdatePop?.rowData : null}
        />
      }
      <section className="section">
        <div className="section-header">
          <h1>Game leaderboard</h1>

          {/* <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">Game leaderboard</div>
          </div> */}
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">Listing</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">

                </div>
              </div>

              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>
            </div>
          </div>
          {gameLeaderboardList?.length > 0 && (
            <Table
              data={gameLeaderboardList}
              columns={columns}
              setFilter={setFilter}
              total={totalLeaderboard}
              filter={filter}
            />
          )}
          {!loading && gameLeaderboardList?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Data Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;

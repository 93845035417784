import React from "react";
import Helmet from "react-helmet";
import { useSelector } from "react-redux";
import { Outlet, Navigate } from "react-router-dom";

const Auth = (props) => {
  const { authToken } = useSelector((state) => state.Auth);



  if (authToken) return <Navigate to="/admin/users" />;
  return (
    <>
      <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APPNAME}`} />
      <Outlet />
    </>
  );
};

export default Auth;

import { DATE_FORMAT } from "helpers/common";
import React from "react";


const Columns = ({ }) => [
  {
    dataField: "Username",
    text: "Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.userDetails?.telegram_username ? row?.userDetails?.telegram_username : row?.userDetails?.first_name}</span>,
  },
  // {
  //   dataField: "roundId",
  //   text: "Game Id",
  //   formatter: (_, row) => <span className="text-capitalize">{console.log("row?.roundId", row)}{row?.roundId}</span>,
  // },
  {
    dataField: "historyType",
    text: "History Type",
    formatter: (_, row) => <span className="text-capitalize">{row?.historyType}</span>,
  },
  {
    dataField: "prevEnergyBalance",
    text: "Pre Energy",
    formatter: (_, row) => <span className="text-capitalize">{row?.prevEnergyBalance}</span>,
  },
  {
    dataField: "energy",
    text: "Energy",
    formatter: (_, row) => <span className="text-capitalize">{row?.energy}</span>,
  },
  {
    dataField: "newEnergyBalance",
    text: "New Energy",
    formatter: (_, row) => <span className="text-capitalize">{row?.newEnergyBalance}</span>,
  },
  {
    dataField: "btncAmount",
    text: "BTNC",
    formatter: (_, row) => <span className="text">{row?.btncAmount}</span>,
  },
  {
    dataField: "prevBtncBalance",
    text: "Pre BTNC",
    formatter: (_, row) => <span className="text">{row?.prevBtncBalance}</span>,
  },
  {
    dataField: "newBtncBalance",
    text: "New BTNC",
    formatter: (_, row) => <span className="text-capitalize">{row?.newBtncBalance}</span>,
  },
  {
    dataField: "date_created_utc",
    text: "created At",
    formatter: (_, row) => <span className="text-capitalize">{row?.date_created_utc && DATE_FORMAT(row?.date_created_utc)}</span>,
  }
];
export default Columns;

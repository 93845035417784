import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { addTaskApi, deleteTask, tasksListApi, updateTaskApi } from "store/actions";
import Table from "components/Table";
import Columns from "./Columns";
import FormikForm from "components/shared/FormikForm";
import AddUpdateTask from "components/Modals/AddUpdateTask";
import { checkPermissions } from "helpers/common";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
  {
    name: "status",
    type: "select",
    label: "",
    placeholder: "Select status",
    options: [
      { value: "active", label: "Active" },
      { value: "inactive", label: "Inactive" },
    ],
  },
];
const initialValuesForFilter = {
  search: "",
  status: "",
};

const List = () => {
  const dispatch = useDispatch();
  const { tasksList, totalTasks, loading } = useSelector((state) => state.Tasks);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [showAddUpdatePop, setShowAddUpdatePop] = useState({
    show: false,
    rowData: null
  });
  const [filter, setFilter] = useState({
    page: 1,
    limit: 100,
    order: 1,
    orderBy: "order",
    search: "",
    status: ""
  });
  const { user } = useSelector((state) => state.Auth);
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false
  })

  useEffect(() => {
    if (user?.permissions) {
      setPermissions({
        add: checkPermissions(["add_task"], user?.permissions),
        edit: checkPermissions(["edit_task"], user?.permissions),
        delete: checkPermissions(["delete_task"], user?.permissions),
      })
    }
  }, [user]);


  const handleTaskStatus = (row, status) => {
    try {
      const callBack = (err, res) => {
        if (err) return;

        getApiData(filter);

      };
      const payload = { _id: row?._id, icon: row?.icon, title: row?.title, link: row?.link, type: row?.type, coins: row?.coins || 0, status: status, count: row?.count }

      dispatch(
        updateTaskApi({
          data: payload,
          callBack,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
    }
  };
  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ handleEditHandler, handleTaskStatus, deleteHandler, handleOrderUpdate, permissions })?.filter((item) => {
      if (item?.text === "Action" && (!permissions.edit && !permissions?.delete)) {
        return false;
      }
      return true;
    }));
  }, [filter, permissions]);

  async function getApiData(query) {
    try {
      dispatch(tasksListApi({ data: query }));

    } catch (error) { }
  }

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      status: info.status,
    });
  };
  const handleEditHandler = (info) => {
    // navigate(`/admin/users/view/${info?._id}`);
    setShowAddUpdatePop({
      show: true,
      rowData: info
    })
  };

  const handleOrderUpdate = (data, order) => {

    if (parseInt(data?.order) === parseInt(order)) return;
    let payload = {
      _id: data?._id, order: parseInt(order)
    }

    dispatch(updateTaskApi({
      data: payload, callBack: (err, response) => {
        if (err) return
        getApiData(filter);
      }
    }))
  }

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this task?");
      if (userConfirmed) {
        const callBack = (err, res) => {
          if (err) {
          } else {
            getApiData(filter);
          }
        };

        dispatch(
          deleteTask({
            data: { _id: id },
            callBack,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };

  const handleConfirmAddUpdate = (values) => {
    console.log("Values", values);
    if (showAddUpdatePop.rowData) {
      let payload = {
        _id: showAddUpdatePop?.rowData?._id, icon: values?.imageUrl, description:values?.description ,title: values?.title, link: values?.link, type: values?.type, coins: values?.coins || 0, count: values?.count || 0, buttonText: values?.buttonText, awardType: values?.awardType, upperButtonText: values?.upperButtonText, status: values?.status
      }
      if (values?.awardType === "other" || values?.awardType === "crypto") {
        payload["awardData"] = {
          name: values?.awardName || "",
          icon: values?.awardIcon || "",
          quantity: values?.awardQuantity || 0,

        }
        if (values?.awardType === "crypto") {
          payload["awardData"] = { ...payload["awardData"], symbol: values?.symbol }
        }
      }
      dispatch(updateTaskApi({
        data: payload, callBack: (err, response) => {
          if (err) return
          setShowAddUpdatePop({ show: false, rowData: null })

        }
      }))
    } else {
      let payload = {
        icon: values?.imageUrl, title: values?.title,description:values?.description, link: values?.link, type: values?.type, coins: values?.coins || 0, count: values?.count || 0, buttonText: values?.buttonText, awardType: values?.awardType, upperButtonText: values?.upperButtonText, status: values?.status
      }
      if (values?.awardType === "other" || values?.awardType === "crypto") {
        payload["awardData"] = {
          name: values?.awardName || "",
          icon: values?.awardIcon || "",
          quantity: values?.awardQuantity || 0,

        }
        if (values?.awardType === "crypto") {
          payload["awardData"] = { ...payload["awardData"], symbol: values?.symbol }
        }
      }
      dispatch(addTaskApi({
        data: payload, callBack: (err, response) => {
          if (err) return
          setShowAddUpdatePop({ show: false, rowData: null })

        }
      }))
    }
  }



  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={"Tasks"} />
      {showAddUpdatePop.show &&
        <AddUpdateTask
          show={showAddUpdatePop.show}
          title={showAddUpdatePop?.rowData ? "Update Task" : "Add Task"}
          loading={loading}
          handleClose={() => setShowAddUpdatePop({ show: false, rowData: null })}
          handleConfirm={handleConfirmAddUpdate}
          rowData={showAddUpdatePop?.rowData}
        />
      }
      <section className="section">
        <div className="section-header">
          <h1>Tasks</h1>
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">Tasks</h2>
                </div>
              </div>
              {permissions?.add ?
                <div className="col-6">
                  <div className="card-header d-flex justify-content-end align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => setShowAddUpdatePop({
                        show: true,
                        rowData: null
                      })}
                    >
                      Add Task
                    </button>
                  </div>
                </div>
                : null}
              < div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>

            </div>
          </div>
          {tasksList?.length > 0 && (
            <Table
              data={tasksList}
              columns={columns}
              setFilter={setFilter}
              total={totalTasks}
              filter={filter}
            />
          )}
          {!loading && tasksList?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Task Found</p>
          )}
        </div>
      </section >
    </>
  );
};

export default List;

import React, { memo, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Card, CardBody, Row, Col, Button } from "reactstrap";

function Table(props) {
  const [pageOptions, setPageOptions] = useState({
    sizePerPage: props?.filter?.limit || 10,
    totalSize: props.total,
    custom: true,
    page: props?.filter?.pageNo || props?.filter?.page || 1,
  })

  useEffect(() => {
    if (props) {
      setPageOptions({
        sizePerPage: props?.filter?.limit || 10,
        totalSize: props.total,
        custom: true,
        page: props?.filter?.pageNo || props?.filter?.page || 1,
      })
    }
  }, [props])
  const handleTableChange = (type, data) => {
    if (type === "pagination") {
      props.setFilter((prevState) => ({ ...prevState, page: data.page }));
    }
    else if (type === "sort") {
      props.setFilter((prevState) => ({
        ...prevState,
        orderBy: data.sortField,
        order: data.sortOrder === "asc" ? 1 : -1, // 1 for ascending, -1 for descending
      }));
    }
  };

  if (props.columns.length <= 0) return <React.Fragment></React.Fragment>;

  const navigate = useNavigate();
  return (
    <Card>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(pageOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="users"
              data={props.data}
              columns={props.columns}
              toggleConfirmModal
              bootstrap4
              search
            >
              {(toolkitProps) => (
                <React.Fragment>
                  {props.children}

                  <div className="clearfix">
                    {props.newButtonLink && (
                      <Button
                        type="button"
                        color="success"
                        className="float-end"
                        onClick={() => navigate(props.newButtonLink)}
                      >
                        <i className="mdi mdi-plus mr-1" />
                        {i18n.t(props.newButtonText)}
                      </Button>
                    )}
                  </div>

                  <div className="table-responsive">
                    <BootstrapTable
                      responsive
                      remote
                      bordered={false}
                      striped={false}
                      classes="table table-centered table-nowrap"
                      headerWrapperClasses={"thead-light"}
                      onTableChange={handleTableChange}
                      selectRow={props.selectRow}
                      {...toolkitProps.baseProps}
                      {...paginationTableProps}
                      {...props.tableProps}
                    />
                  </div>

                  <Row className="align-items-md-center mt-30">
                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                      <PaginationListStandalone {...paginationProps} />
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
}

Table.propTypes = {
  total: PropTypes.number,
  newButtonLink: PropTypes.string,
  newButtonText: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  handleTableChange: PropTypes.func,
  tableProps: PropTypes.object,
  filter: PropTypes.object,
};

Table.defaultProps = {
  total: 0,
  data: [],
  columns: [],
  tableProps: {},
  newButtonText: "Add New",
};

export default memo(Table);

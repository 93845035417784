import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    comment: Yup.string().required('Comment is required'),
});

export default function CommentUpdateUser({
    show,
    handleClose,
    handleSubmit,
    loading
}) {

    const [initialValues, setInitialValues] = useState({
        comment: '',
    });


    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header className="py-3 text-dark" closeButton>
                <Modal.Title className="fw-bold text-dark">Add Comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {({ }) => {

                        return (
                            <Form>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <label htmlFor="comment" className="form-label">Comment</label>
                                        <Field placeholder="Enter a reason for the update" name="comment" type="text" className="form-control" />
                                        <ErrorMessage name="comment" component="div" className="text-danger mt-1" />
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button disabled={loading} type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

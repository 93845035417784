import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { Row, Col, FormGroup, Label, Input, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./instructions";
import { Alert, Button, CardHeader } from "react-bootstrap";
import { get } from "helpers/api_helper";
import { GET_STATISTICS_COUNT, GET_STATISTICS_EXCELL_FILE } from "helpers/url_helper";
import { getSocketEmit, getValuesSocketData } from "helpers/secretManager";
import RemainingCrypto from "./Components/RemainingCrypto/List";
import StatisticsLinesCount from "./Components/StatisticsGraphs/StatisticsLinesCount";
import StatisticsGraphs from "./Components/StatisticsGraphs/Statistics";
import { toast } from "react-toastify";
import { formatNumber } from "helpers/common";
import useSocket from "hooks/useSocket";
import StatisticsWithFilter from "./Components/StatisticsGraphs/StatisticsWithFilter";

const Statistics = () => {
    const { socket, socketConnect } = useSocket();
    const [dataCounts, setDataCounts] = useState({
        totalUserCount: 0,
        newUsersCount: 0,
        dailyActiveUsers: 0,
        totalBtnc: 0,
        todayBtnc: 0,
        gameRounds: 0,
        totalCoins: [],
        todayCoins: [],
        currentBTNC: 0
    });

    useEffect(() => {
        console.log("socketConnect", socketConnect)
        if (socketConnect) {
            fetchData();
        }
    }, [socketConnect]);


    const fetchData = async () => {
        const response = await getSocketEmit(socket, "getStatisticsData", { key: "totalUserCount" });
        setDataCounts(pre => ({ ...pre, totalUserCount: typeof response?.data !== "string" ? response?.data : 0 }));
        const response2 = await getSocketEmit(socket, "getStatisticsData", { key: "newUsersCount" });
        setDataCounts(pre => ({ ...pre, newUsersCount: typeof response2?.data !== "string" ? response2?.data : 0 }));
        // const response3 = await getSocketEmit(socket, "getStatisticsData", { key: "dailyActiveUsers" });
        // setDataCounts(pre => ({ ...pre, dailyActiveUsers: typeof response3?.data !== "string" ? response3?.data : 0 }));
        const response4 = await getSocketEmit(socket, "getStatisticsData", { key: "totalBtnc" });
        setDataCounts(pre => ({ ...pre, totalBtnc: typeof response4?.data !== "string" ? response4?.data : 0 }));
        const response5 = await getSocketEmit(socket, "getStatisticsData", { key: "todayBtnc" });
        setDataCounts(pre => ({ ...pre, todayBtnc: typeof response5?.data !== "string" ? response5?.data : 0 }));
        const response6 = await getSocketEmit(socket, "getStatisticsData", { key: "gameRounds" });
        setDataCounts(pre => ({ ...pre, gameRounds: typeof response6?.data !== "string" ? response6?.data : 0 }));
        const response7 = await getSocketEmit(socket, "getStatisticsData", { key: "totalCoins" });
        setDataCounts(pre => ({ ...pre, totalCoins: typeof response7?.data !== "string" ? response7?.data : [] }));
        const response8 = await getSocketEmit(socket, "getStatisticsData", { key: "todayCoins" });
        setDataCounts(pre => ({ ...pre, todayCoins: typeof response8?.data !== "string" ? response8?.data : [] }));
        const response9 = await getSocketEmit(socket, "getStatisticsData", { key: "currentBTNC" });
        setDataCounts(pre => ({ ...pre, currentBTNC: typeof response9?.data !== "string" ? response9?.data : [] }));
    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} lg={12}>
                            <Card>
                                <CardBody className="spinner-content">
                                    <Col lg={8}>
                                        <Instructions />
                                    </Col>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={12} lg={12} className="my-2">
                            <Row>
                                <Col lg="3" md="4" sm="6" className="my-2">
                                    <Card className="my-2">
                                        <CardHeader>
                                            Game Rounds
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.gameRounds)}
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">

                                    <Card className="my-2">
                                        <CardHeader>
                                            New Users
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.newUsersCount)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">
                                    <Card className="my-2">
                                        <CardHeader>
                                            Total Users
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.totalUserCount)}
                                        </CardBody>
                                    </Card>

                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">

                                    <Card className="my-2">
                                        <CardHeader>
                                            Today BTNC
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.todayBtnc)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">

                                    <Card className="my-2">
                                        <CardHeader >
                                            Total BTNC
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.totalBtnc)}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col lg="3" md="4" sm="6" className="my-2">

                                    <Card className="my-2">
                                        <CardHeader >
                                            Current BTNC
                                        </CardHeader>
                                        <CardBody className="spinner-content">
                                            {formatNumber(dataCounts?.currentBTNC)}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        {dataCounts?.totalCoins?.length ?
                            <Col lg={12}>
                                <p>Total Coins</p>
                                <Row>
                                    {dataCounts?.totalCoins?.map((coin, key) => (
                                        <Col key={key} lg="3" md="4" sm="6" className="my-2">
                                            <Card className="my-2">
                                                <CardHeader className="text-uppercase">
                                                    {coin?._id}
                                                </CardHeader>
                                                <CardBody className="spinner-content">
                                                    {formatNumber(coin?.totalAmount)}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>

                            </Col>
                            : null}
                        {dataCounts?.todayCoins?.length ?
                            <Col lg={12}>
                                <p>Today Coins</p>
                                <Row>
                                    {dataCounts?.todayCoins?.map((coin, key) => (
                                        <Col key={key} lg="3" md="4" sm="6" className="my-2">
                                            <Card className="my-2">
                                                <CardHeader className="text-uppercase">
                                                    {coin?._id}
                                                </CardHeader>
                                                <CardBody className="spinner-content">
                                                    {formatNumber(coin?.totalAmount)}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>

                            : null}
                        <Col lg={12} className="my-2">
                            <hr />
                        </Col>
                        <Col lg={12} className="my-2">
                            <StatisticsWithFilter />
                        </Col>

                        <Col lg={12} className="my-2">
                            <RemainingCrypto />
                        </Col>


                        <Col lg={12} className="my-2">
                            <StatisticsLinesCount />
                        </Col>


                        <Col lg={12} className="my-2">
                            <StatisticsGraphs />
                        </Col>

                    </Row>
                </Container >
            </div >

        </React.Fragment >
    );
};

export default Statistics;
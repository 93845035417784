import { combineReducers } from "@reduxjs/toolkit";

//reducers
import authReducer from "./auth/reducer";
import userReducer from "./user/reducer";
import settingReducer from "./settings/reducer";
import languageReducer from "./language/reducer";
import gameReducer from "./game/reducer";
import tasksReducer from "./tasks/reducer";
import roadmapReducer from "./roadmaps/reducer";

const rootReducer = combineReducers({
  Auth: authReducer,
  Users: userReducer,
  Settings: settingReducer,
  Language: languageReducer,
  Game: gameReducer,
  Tasks: tasksReducer,
  Roadmaps: roadmapReducer
});

export default rootReducer;
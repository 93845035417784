import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
// Actions
import { getSettings, putMobileSettings, updateEnergySettings, updateSettings } from "store/actions";
import { Row, Col, FormGroup, Label, Input, Button, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./Instructions";
import Select from 'react-select';
import { Alert } from "react-bootstrap";
import { checkPermissions } from "helpers/common";

const EnergySettings = () => {
  // const  t =useTransition();
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(({ Settings }) => Settings);
  const [fields, setFields] = useState(
    {
      "energy": {
        "dailyFreeEnergy": 0,
        "maxEnergyBalance": 0,
        "chargeFor1Energy": 0
      }
    }
  );
  const { user } = useSelector((state) => state.Auth);
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false
  })

  useEffect(() => {
    if (user?.permissions) {
      setPermissions({
        add: false,
        edit: checkPermissions(["edit_energy_settings"], user?.permissions),
        delete: false
      })
    }
  }, [user]);

  useEffect(() => {
    dispatch(getSettings());
  }, [])

  useEffect(() => {
    if (settings?._id) {
      console.log("settings", settings)
      setFields((prevState) => ({
        ...prevState,
        energy: settings?.energy
      }));
    }
  }, [JSON.stringify(settings)]);


  const handleChange = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: Number(value) }
    }
    setFields(copySettings);
  };


  function submit() {
    dispatch(
      updateEnergySettings({
        data: fields.energy
      }));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={12} lg={12}>
              <Card>
                <CardBody className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  {loading && <div className="spinner"></div>}

                  <Col lg={8}>
                    <Instructions />
                  </Col>

                  <Col lg={8}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Max Energy Balance</Label>

                          <Input
                            type="number"
                            name="maxEnergyBalance"
                            value={fields?.energy?.maxEnergyBalance}
                            onChange={
                              (e) => handleChange("energy")(e)
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6} />

                      <Col md={6}>
                        <FormGroup>
                          <Label>Charge for 1 Energy</Label>

                          <Input
                            type="number"
                            value={fields?.energy?.chargeFor1Energy}
                            name="chargeFor1Energy"
                            onChange={
                              (e) => handleChange("energy")(e)
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Daily Free Energy</Label>

                          <Input
                            type="number"
                            value={fields?.energy?.dailyFreeEnergy}
                            name="dailyFreeEnergy"
                            onChange={
                              (e) => handleChange("energy")(e)
                            }
                            min={0}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      {permissions.edit ?
                        <Col className="d-flex justify-content-end">
                          {loading && <Spinner color="primary" className="mr-2" />}

                          <Button className="btn btn-primary" onClick={submit}>
                            Save Changes
                          </Button>
                        </Col>
                        : null}

                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

export default EnergySettings;
import { DATE_FORMAT } from "helpers/common";
import React from "react";


const Columns = ({ }) => [
  {
    dataField: "WalletId",
    text: "WalletId",
    formatter: (_, row) => <span className="text-capitalize">{row?.invitedUserDetails?.username}</span>,
  },
  {
    dataField: "telegram",
    text: "Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.invitedUserDetails?.telegram_username ? row?.invitedUserDetails?.telegram_username : row?.invitedUserDetails?.first_name || "---"}</span>,
  },
  {
    dataField: "earning",
    text: "Earnings",
    formatter: (_, row) => <span className="text-capitalize">{row?.earning}</span>,
  },
  {
    dataField: "parent",
    text: "Parent Wallet Id",
    formatter: (_, row) => <span className="text">{row?.parentUserDetails?.username || "---"}</span>,
  },
  {
    dataField: "telegram",
    text: "Parent Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.parentUserDetails?.telegram_username ? row?.parentUserDetails?.telegram_username : row?.parentUserDetails?.first_name || "---"}</span>,
  },
  {
    dataField: "Date",
    text: "Date",
    formatter: (_, row) => <span className="text">{row?.invitedUserDetails?.createdAt ?
      DATE_FORMAT(row?.invitedUserDetails?.createdAt)
      : null}</span>,
  }
];
export default Columns;

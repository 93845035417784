import { updateToken } from "./api_helper";
import moment from "moment";
import CryptoJS from "crypto-js";

export function DATE_FORMAT(inputDate) {
  const formattedDate = moment(inputDate).utcOffset() === 0 ? moment.utc(inputDate).format("DD.MM.YY HH:mm:ss") : moment(inputDate).format("DD.MM.YY HH:mm:ss");
  return formattedDate;
}

export function FORMAT_TEXT(text) {
  const text_ = text?.length > 20 ? text?.substring(0, 20) + "..." : text;
  return text_;
}

export function FORMAT_TEXT_Temp(text) {
  const text_ = text?.length > 40 ? text?.substring(0, 40) + "..." : text;
  return text_;
}

export function convertTimestampToDate(timestamp, days) {
  const originalDate = new Date(timestamp);
  const newDate = new Date(originalDate);
  newDate.setDate(newDate.getDate() + days);
  const year = newDate.getFullYear();
  const month = (newDate.getMonth() + 1).toString().padStart(2, "0");
  const day = newDate.getDate().toString().padStart(2, "0");
  return `${day}-${month}-${year}`;
}

export function formatTimestamp(timestamp, timeZone) {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: timeZone, // Specify the desired time zone here
  };
  return date.toLocaleString("en-US", options);
}

export function IS_BASE64(input) {
  try {
    const base64Data = input.split(",")[1];
    return btoa(atob(base64Data)) === base64Data;
  } catch (e) {
    return false;
  }
}

export function updateAdminToken(token) {
  updateToken(token);
}


export const generateSignature = async (secret) => {
  try {

    const nonce = Date.now().toString(); // Unique nonce for every request

    // Generate HMAC using CryptoJS
    const hmac = CryptoJS.HmacSHA256(nonce, secret);
    const signature = hmac.toString(CryptoJS.enc.Hex);

    return {
      signature,
      nonce,
    };
  } catch (err) {
    return {
      signature: null,
      nonce: null,
    };
  }
};

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// Function to get a cookie by name
export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export function areCookiesEnabled() {
  // // Set a test cookie with SameSite=None and Secure only if on HTTPS
  // const isSecure = window.location.protocol === 'https:';
  // document.cookie = `testcookie=1; path=/; SameSite=Lax; ${isSecure ? 'Secure' : ''}`;

  // // Check if the cookie exists
  // const cookiesEnabled = document.cookie.indexOf("testcookie") !== -1;

  // // Clean up by deleting the test cookie
  // document.cookie = "testcookie=1; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; SameSite=None; " + (isSecure ? 'Secure' : '');

  console.log("document.cookie:", document.cookie);
  // return cookiesEnabled;
}


export const checkPermissions = (permissionsKeys, permissions) => {
  console.log("permissionsKeys", permissionsKeys);
  console.log("permissions", permissions)
  if (!permissions?.length) return false;
  if (!permissionsKeys?.length) return false;
  return permissions?.some((key) => permissionsKeys.includes(key));
}


export function formatNumber(num) {
  // Check if the input is a valid number
  if (typeof num !== 'number' || isNaN(num)) {
    return 0; // Return 'N/A' or '0' based on preference
  }

  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


export function formatNumberString(num) {
  // Check if the input is a valid number
  if (typeof num !== 'number' || isNaN(num)) {
    return 0; // Return 'N/A' or '0' based on preference
  }

  if (num >= 1_000_000_000) {
    return (num / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B';
  } else if (num >= 1_000_000) {
    return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M';
  } else if (num >= 1_000) {
    return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'K';
  }

  return num.toString(); // Return as is for numbers < 1,000
}

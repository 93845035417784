import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import * as Path from "routes/Path/index";
import { gameHistoryListApi } from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import FormikForm from "components/shared/FormikForm";
import GameHistoryModal from "components/Modals/Gamehistory/GameHistoryModal";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
];
const initialValuesForFilter = {
  search: "",
};

const List = ({ userDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [gameModalShow, setGameModalShow] = useState(false);
  const { gameHistoryList, loading, totalGameHistory } = useSelector(
    ({ Game }) => Game
  );
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [id, setId] = useState(null);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    status: "All",
    userId: userDetails?._id,
  });

  useEffect(() => {
    if (userDetails?._id) {
      getApiData({ ...filter, userId: userDetails?._id });
      setcolumns(Columns({ handleEditHandler }));
    }
  }, [filter, userDetails]);

  async function getApiData(query) {
    try {
      dispatch(gameHistoryListApi({ data: query }));
    } catch (error) {}
  }

  const handleEditHandler = (info) => {
    setGameModalShow(true);
    setId(info?._id);
    // navigate(`/admin/game-history/view/${info?._id}`);
  };

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      status: info.status,
    });
  };

  return (
    <>
      <GameHistoryModal
        show={gameModalShow}
        handleClose={() => {
          setGameModalShow(false);
        }}
        title={"Game Details"}
        id={id}
      />
      <div className="section-body">
        <div className="card">
          <div className="row justify-content-between">
            <div className="col-6">
              <div className="card-header">
                <h2 className="section-title">Listing</h2>
              </div>
            </div>
            <div className="col-6">
              <div className="card-header d-flex justify-content-end align-items-center"></div>
            </div>
            <div className="col-12">
              <div className="card-header">
                <FormikForm
                  initialValues={initialValuesForFilter}
                  onSubmit={handleFilterForm}
                  loading={loading}
                  inputFields={InputFieldsForSearch}
                  buttonText={"Search"}
                />
              </div>
            </div>
          </div>
        </div>
        {gameHistoryList?.length > 0 && (
          <Table
            data={gameHistoryList}
            columns={columns}
            setFilter={setFilter}
            total={totalGameHistory}
            filter={filter}
          />
        )}
        {!loading && gameHistoryList?.length === 0 && (
          <p style={{ textAlign: "center" }}>No Record Found</p>
        )}
      </div>
    </>
  );
};

export default List;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Path from "routes/Path/index";
import { getUserDetails, updateUserStatus } from "store/actions";
import { Badge, Col, Row, Stack } from "react-bootstrap";


const SubAminView = () => {
    const { id } = useParams();

    const dispatch = useDispatch();
    const { userDetails, loadingUpBalance } = useSelector(({ Users }) => Users);
    const { settings, loading, error } = useSelector(({ Settings }) => Settings);

    const [activeTab, setActiveTab] = useState("energyHistory");
    const [balance, setBalance] = useState({
        btncBalance: 0,
        energyBalance: 0,
        adminNote: ""
    })

    console.log("userDetails", userDetails)
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id]);


    const fetchData = (id) => {
        dispatch(getUserDetails({
            id, callBack: (err, res) => {
                if (err) return;

            }
        }));
    }

    const handleUserStatus = (id, status) => {
        try {
            const callback = (err, res) => {
                if (err) return;
                if (id) {
                    fetchData(id);
                }

            };

            dispatch(
                updateUserStatus({
                    data: { _id: id, status: status },
                    callback,
                })
            );
        } catch (error) {
            console.log(error, "<===err");
        }
    };
    console.log("balance", balance)


    return (
        <>
            <section className="section">
                <div className="section-header">
                    <h1>Sub Admin Details</h1>

                    {/* <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item active">
                            <Link to={"/admin/" + Path.subadmins}>Sub Admins</Link>
                        </div>
                        <div className="breadcrumb-item">Sub Admin Details</div>
                    </div> */}
                </div>
                <div className="section-body">
                    <Row>
                        <Col sm={12} lg={6}>
                            <div className="py-2">
                                <div className="card">
                                    <div className="row justify-content-between p-4">
                                        <p><b>Name</b>: <span>{userDetails?.name}</span></p>
                                        <p><b>Email</b>: <span>{userDetails?.email}</span></p>
                                        <p><b>Permissions</b>: </p>
                                        <Stack direction="horizontal" gap={2}>
                                            {userDetails?.permissions?.map((permission, index) => {
                                                return (
                                                    <>
                                                        <Badge key={index} pill bg="primary">
                                                            {permission}
                                                        </Badge>
                                                    </>
                                                )
                                            })}
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section >
        </>
    );
};

export default SubAminView;

import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { del, get, post, put } from "helpers/api_helper";
import { toast } from "react-toastify";
import { getValuesSocketData } from "helpers/secretManager";

export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.GET_USERS, data, { signature, nonce });
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "updateUser",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await put(URL.UPDATE_USER + data?._id, data, { signature, nonce });
      if (response?.status === "failure") {
        callback && callback(response?.message, null);
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message);
      }
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const updateUserStatus = createAsyncThunk(
  "updateUserStatus",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await put(URL.UPDATE_USER_STATUS + data?._id, data, { signature, nonce });
      if (response?.status === "failure") {
        callback && callback(response?.message, null);
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message);
      }
      callback && callback(null, response.data);
      toast.success("Status updated successfully!");
      return response;
    } catch (error) {
      toast.error(error?.data?.message || error.message || "Something went wrong!")
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const AddUser = createAsyncThunk(
  "AddUser",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.ADD_USER, data, { signature, nonce });
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const updateUserBalance = createAsyncThunk(
  "updateUserBalance",
  async ({ data, callBack }, Thunk) => {
    try {
      const payload = {
        btncBalance: data.btncBalance,
        energyBalance: data.energyBalance,
        adminNote: data.adminNote,
        comment: data.comment,
        isVipUser: data.isVipUser
      }
      const { signature, nonce } = await getValuesSocketData();

      let response = await put(URL.UPDATE_USER_BALANCE + data?._id, payload, { signature, nonce })
      callBack(null, response)
      // toast.success(response?.message);
      return response.data
    } catch (error) {
      callBack(error.message)
      // toast.error(error?.message || "Something went wrong!");
      return Thunk.rejectWithValue(error)
    }
  }
)


export const getUserDetails = createAsyncThunk(
  "getUserDetails",
  async ({ id, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await get(URL.USER_DETAILS + id, { signature, nonce })
      if (response.status === "failed") {
        callBack && callBack(response?.message, "");
      }
      let data = { ...response.data }
      if (response?.currentRank) {
        data["currentRank"] = response?.currentRank;
      }
      callBack && callBack("", data);

      return data;
    } catch (error) {
      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error)
    }
  }
)


export const gameEndApi = createAsyncThunk(
  "gameEndApi",
  async ({ data, callBack }, Thunk) => {
    try {

      const { signature, nonce } = await getValuesSocketData();

      let response = await put(URL.GAME_END_BY_ADMIN + data?._id, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)

//add subadmins

export const addSubAdminApi = createAsyncThunk(
  "addSubAdminApi",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.ADD_SUB_ADMIN, data, { signature, nonce });
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteUserApi = createAsyncThunk(
  "deleteUserApi",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await del(URL.DELETE_USER + "/" + data?._id, { signature, nonce });
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callback && callback(null, response);
      return response;
    } catch (error) {
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const refferalsListApi = createAsyncThunk(
  "refferalsListApi",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.USER_REFFERALS_LIST, data, { signature, nonce });

      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const earningsListApi = createAsyncThunk(
  "earningsListApi",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      const response = await post(URL.USER_EARNINGS_LIST, data, { signature, nonce });

      callback && callback(null, response);
      return response;
    } catch (error) {
      console.log(error, "<===error");
      callback && callback(error?.data?.message || error.message || error);
      return Thunk.rejectWithValue(error);
    }
  }
);

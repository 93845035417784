import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post, del, put } from "helpers/api_helper";
import { toast } from "react-toastify";
import { getValuesSocketData } from "helpers/secretManager";

export const getRoadmapsList = createAsyncThunk(
  "getRoadmapsList",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(`${URL.GET_ROADMAP_LIST}`, data, { signature, nonce });
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const addRoadmapApi = createAsyncThunk(
  "addRoadmapApi",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.ADD_ROADMAP, data, { signature, nonce });
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message)
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error.message || "Something went wrong!", null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const editRoadmapApi = createAsyncThunk(
  "editRoadmapApi",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await put(URL.EDIT_ROADMAP + data._id, data, { signature, nonce });
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message)
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const deleteRoadmap = createAsyncThunk(
  "deleteRoadmap",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await del(URL.DELETE_ROADMAP + data._id, data, { signature, nonce });
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message)
      callback && callback(null, response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error.message, null);
      return Thunk.rejectWithValue(error);
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit"
import { post, get, put, updateCSRFToken, del } from "helpers/api_helper"
import { decrypt, getValuesSocketData } from "helpers/secretManager"
import * as URL from "helpers/url_helper"
import { toast } from "react-toastify"

export const tasksListApi = createAsyncThunk(
  "tasksListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.TASK_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const addTaskApi = createAsyncThunk(
  "addTaskApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.ADD_TASK, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message)
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const updateTaskApi = createAsyncThunk(
  "updateTaskApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await put(URL.UPDATE_DELETE_TASK + data?._id, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      toast.success(response?.message)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)

export const deleteTask = createAsyncThunk(
  "deleteTask",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await del(URL.UPDATE_DELETE_TASK + data?._id, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const logsListApi = createAsyncThunk(
  "logsListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.LOGS_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)
import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Dropdown } from "react-bootstrap";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })

const STATUS = {
  "active": "Active",
  "inactive": "Inactive",
  "blocked": "Blocked",
  "deleted": "Deleted"
}

const Columns = ({ handleEditHandler }) => [
  {
    dataField: "roundId",
    text: "Round Id",
    formatter: (_, row) => <span className="text-capitalize">{row.roundId}</span>,
  },
  {
    dataField: "ID",
    text: "ID",
    formatter: (_, row) => <span className="text-capitalize">{row?.userDetails?.username}</span>,
  },
  {
    dataField: "Username",
    text: "Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.userDetails?.telegram_username ? row?.userDetails?.telegram_username : row?.userDetails?.first_name}</span>,
  },
  {
    dataField: "lineCount",
    text: "Line Count",
    formatter: (_, row) => <span className="text-capitalize">{row.lineCount}</span>,
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },

  },

  {
    dataField: "prevEnergyBalance",
    text: "Pre Energy Balance",
    formatter: (_, row) => <span className="text">{row.prevEnergyBalance}</span>,
  },
  // {
  //   dataField: "Winnings",
  //   text: "Winnings",
  //   formatter: (_, row) => <span className="text">
  //     {row?.winnings?.length ? (
  //       row?.winnings?.length === 1 ? (
  //         <div className="d-flex align-items-center">
  //           <span className="icn me-1">
  //             <img
  //               src={row?.winnings?.[0]?.icon}
  //               alt=""
  //               style={{ height: 14, width: 14 }}
  //               className=" img-fluid object-fit-contain"
  //             />
  //           </span>
  //           {row?.winnings?.[0]?.amount || 0}
  //         </div>
  //       ) : (
  //         <Dropdown className={``}>
  //           <Dropdown.Toggle
  //             variant="transparent"
  //             id="dropdown-basic"
  //             className="rounded-2 d-flex align-items-center justify-content-center px-1 border-0"
  //           >
  //             <span className="icn me-1"></span>
  //             See all...
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu
  //             className={` p-0 pb-2`}
  //           >
  //             <ol className="list-unstyled ps-0 mb-0">
  //               {row?.winnings?.map((win, key) => (
  //                 <li
  //                   key={key}
  //                   className="d-flex align-items-center px-2"
  //                 >
  //                   <span className="numb  fw-bold">
  //                     {key + 1}.
  //                   </span>
  //                   <Button
  //                     className="d-flex align-items-center  py-1"
  //                     variant="transparent"
  //                   >
  //                     <span className="icn me-1">
  //                       <img
  //                         src={win?.icon}
  //                         alt=""
  //                         style={{
  //                           height: 14,
  //                           width: 14,
  //                         }}
  //                         className="img-fluid object-fit-contain"
  //                       />
  //                     </span>
  //                     {win?.amount || 0}
  //                   </Button>
  //                 </li>
  //               ))}
  //             </ol>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       )
  //     ) : (
  //       "--"
  //     )}
  //   </span>,
  // },
  {
    dataField: "Btnc Winnings",
    text: "Btnc Winnings",
    formatter: (_, row) => {
      const btncWinnings = row?.winnings?.find(win => win.symbol === "BTNC");
      return (
        <span className="text">
          {
            btncWinnings ? (
              <div className="d-flex align-items-center">
                <span className="icn me-1">
                  <img
                    src={btncWinnings.icon}
                    alt={btncWinnings.name}
                    style={{ height: 14, width: 14 }}
                    className="img-fluid object-fit-contain"
                  />
                </span>
                {btncWinnings.amount || 0}
              </div>
            ) : (
              <span>0</span>
            )
          }
        </span>
      );
    },
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },
  {
    dataField: "Crypto Winnings",
    text: "Crypto Winnings",
    formatter: (_, row) => {
      const cryptoWinnings = row?.winnings?.filter(win => win.symbol !== "BTNC");
      return (
        <span className="text">
          {
            cryptoWinnings && cryptoWinnings.length > 0 ? (
              <div className="d-flex flex-column">
                {cryptoWinnings.map((win, index) => (
                  <div key={index} className="d-flex align-items-center mb-1">
                    <span className="icn me-1">
                      <img
                        src={win.icon}
                        alt={win.name}
                        style={{ height: 14, width: 14 }}
                        className="img-fluid object-fit-contain"
                      />
                    </span>
                    {win.amount || 0}
                  </div>
                ))}
              </div>
            ) : (
              <span>--</span>
            )
          }
        </span>
      );
    },
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },

  {
    dataField: "status",
    text: "status",
    formatter: (_, row) => <span className="text-capitalize">{row?.status}</span>,
  },
  {
    text: "completedAt",
    dataField: "completed At",
    formatter: (_, row) => row.completedAt && DATE_FORMAT(row.completedAt),
  },
  // {
  //   text: "endedAt",
  //   dataField: "ended At",
  //   formatter: (_, row) => row.endedAt && DATE_FORMAT(row.endedAt),
  // },

  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (


      <button
        className="fas fa-eye"
        style={{
          fontSize: "16px",
          backgroundColor: "#fff",
          border: "none",
        }}
        title="Edit"
        onClick={() =>
          handleEditHandler(row)
        }
      ></button>

    ),
  },
];
export default Columns;

import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })


const Columns = ({ }) => [
  {
    dataField: "userId",
    text: "User Id",
    formatter: (_, row) => <span className="text">{row?.userDetails?.userID || "--"}</span>,
  },
  {
    dataField: "username",
    text: "Username",
    formatter: (_, row) => <span className="text">{row?.userDetails?.username || "--"}</span>,
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (_, row) => <span className="text-capitalize">{row?.type}</span>,
  },
  {
    dataField: "text",
    text: "Title",
    formatter: (_, row) => <span className="text-capitalize">{row?.text}</span>,
  },
  {
    dataField: "comment",
    text: "Comment",
    formatter: (_, row) => <span className="text-capitalize">{row?.comment || "--"}</span>,
  },
  {
    dataField: "device",
    text: "Device",
    formatter: (_, row) => <span className="text-capitalize">{row?.deviceInfo}</span>,
  },
  {
    text: "Created At",
    dataField: "date_created_utc",
    formatter: (_, row) => row.date_created_utc && DATE_FORMAT(row.date_created_utc),
  }
];
export default Columns;

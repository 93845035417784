import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";


const initialState = {
  user: null,
  signupuser: null,
  forgetpass: null,
  isAuthenticated: false,
  routeType: null,
  loading: false,
  exportLoading: false,
};

const slice = createSlice({
  name: "auth",
  initialState: { ...initialState },
  reducers: {
    logoutUser: (state, action) => {
      return { ...initialState, user: null };
    },
    setRouteType: (state, action) => {
      state.routeType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(Act.loginUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.loginUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(Act.loginUser.rejected, (state, action) => {
      state.user = initialState.user;
      state.loading = false;
    });

    builder.addCase(Act.updateAdmin.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.updateAdmin.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.role === "ADMIN") {
        state.user = action.payload;
      }
    });
    builder.addCase(Act.updateAdmin.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Act.viewUser.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.viewUser.fulfilled, (state, action) => {
      state.loading = false;
      state.user = action.payload;

    });
    builder.addCase(Act.viewUser.rejected, (state, action) => {
      state.loading = false;
      state.user = null;

    });

    builder.addCase(Act.resetPass.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.resetPass.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.resetPass.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Act.uploadFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.uploadFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.uploadFile.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.getCsrfToken.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.getCsrfToken.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.getCsrfToken.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Act.userSessionLogout.pending, (state, action) => {
      state.logoutLoading = true;
    });
    builder.addCase(Act.userSessionLogout.fulfilled, (state, action) => {
      state.logoutLoading = false;
      state.user = null;
    });
    builder.addCase(Act.userSessionLogout.rejected, (state, action) => {
      state.logoutLoading = false;
    });
    builder.addCase(Act.changePassword.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.changePassword.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.changePassword.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.exportUsersList.pending, (state, action) => {
      state.exportLoading = true;
    });
    builder.addCase(Act.exportUsersList.fulfilled, (state, action) => {
      state.exportLoading = false;
    });
    builder.addCase(Act.exportUsersList.rejected, (state, action) => {
      state.exportLoading = false;
    });
  },
});

export default slice;

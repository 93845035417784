import moment from "moment";

export const adminPermissions = [
    "profile",
    "change_password",
    "subadmins",
    "statistics",
    "manage_users",
    "game_history",
    "leaderboard",
    "claim_history",
    "logs",
    "tasks",
    "roadmap",
    "add_roadmap",
    "edit_roadmap",
    "delete_roadmap",
    "add_task",
    "edit_task",
    "delete_task",
    "edit_leaderboard_points",
    "edit_game_settings",
    "edit_leaderboard_settings",
    "edit_energy_settings",
    "edit_maintenance_settings",
    "edit_users",
    "game_settings",
    "leaderboard_settings",
    "energy_settings",
    "maintenance_settings"];


// Function to generate week options
export const generateWeekOptions = (timezone) => {
    const now = moment().tz(timezone);
    const totalWeeks = now.isoWeeksInYear(); // Get total weeks in the current year
    const options = [];

    for (let i = 1;i <= totalWeeks;i++) {
        options.push({ value: i, label: `Week ${i}` });
    }

    return options;
};
// export const getWeeksInYearForOffset = () => {
//     // Get current year dynamically
//     const year = new Date().getFullYear(); // Current year

//     // Define the timezone (Etc/GMT-4 represents GMT+4)
//     const timezone = "Etc/GMT-4";

//     // Get the last day of the current year in the specified timezone
//     const lastDayOfYear = moment.tz(`${year}-12-31`, timezone);

//     // Get the first day of the next year
//     const firstDayOfNextYear = moment.tz(`${year + 1}-01-01`, timezone);

//     // Get the number of days in the last week of the current year (Dec 29 to Dec 31)
//     const lastWeekDaysInCurrentYear = [
//         lastDayOfYear.clone().subtract(2, 'days'),
//         lastDayOfYear.clone().subtract(1, 'days'),
//         lastDayOfYear
//     ];

//     // Check if the last days of December belong to Week 53 of the current year
//     const daysInLastWeek = lastWeekDaysInCurrentYear.filter(day => day.year() === year).length;

//     // If the last days of December (2 or 3 days) are part of the current year, add Week 53
//     const totalWeeks = (daysInLastWeek >= 1) ? 53 : 52;  // Ensure that even 1 day in the last week counts as Week 53

//     const weeks = [];

//     // Add Week 53 if applicable
//     if (totalWeeks === 53) {
//         weeks.push({ value: 53, label: "Week 53" });
//     }

//     // Add the regular weeks from Week 1 to Week 52 (or 53)
//     for (let i = 1;i <= totalWeeks - 1;i++) {
//         weeks.push({ value: i, label: `Week ${i}` });
//     }

//     // Only add Week 1 for next year if it is not already part of the current year
//     // if (totalWeeks === 53) {
//     //     weeks.push({ value: 1, label: "Week 1" });
//     // }

//     // Sort the weeks array in ascending order
//     weeks.sort((a, b) => a.value - b.value);

//     return weeks;
// };

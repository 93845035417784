import React from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import Helmet from "react-helmet"
import * as Path from "routes/Path/index"
import { DASHBOARD } from "common/viewContent"

const Dashboard = (props) => {
  console.log("inside dashboard ------->", props)
  return (
    <>
      <Helmet title={DASHBOARD.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{DASHBOARD.CURRENT_MODULE}</h1>
          <div className="section-header-breadcrumb">
            <div className="breadcrumb-item">Welcome to Admin ! </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="card card-statistic-2">
      
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.Auth.authToken,
    user: state.Auth.Auth,
  }
}

function mapDispatchToProps(dispatch) {
  return { dispatch }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import moment from "moment";
import { getValuesSocketData } from "helpers/secretManager";
import { GET_STATISTICS_EXCELL_FILE } from "helpers/url_helper";
import { get } from "helpers/api_helper";
import { toast } from "react-toastify";


export default function ExportStatistics({
    show,
    handleClose
}) {

    const [initialValues, _] = useState({
        date: moment().format("YYYY-MM-DD")
    });
    const [loading, setLoading] = useState(false);
    const onSubmit = async (values) => {

        try {
            setLoading(true);
            const { signature: signature2, nonce: nonce2 } = await getValuesSocketData();
            let url = `${GET_STATISTICS_EXCELL_FILE}`;
            if (values?.date) {
                url = url + `?date=${values?.date}`
            }
            const res3 = await get(`${url}`, { signature: signature2, nonce: nonce2 })
            setLoading(false);
            if (res3?.data) {
                toast.success(res3?.message);
                window.open(res3?.data, '_blank');
                handleClose();
            }
        } catch (e) {
            setLoading(false)
            console.log("error export", e)
        } finally {
            setLoading(false)
        }

    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="md"
        >
            <Modal.Header className="py-3 text-dark" closeButton>
                <Modal.Title className="fw-bold text-dark">Export Statistics</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={null}
                    onSubmit={(values) => {

                        onSubmit(values);
                    }}
                >
                    {({ values }) => {

                        console.log("values", values)
                        return (
                            <Form>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="name" className="form-label">Date</label>
                                        <Field name="date" type="date" className="form-control" />
                                        <ErrorMessage name="date" component="div" className="text-danger mt-1" />
                                    </div>


                                </div>

                                <div className="d-flex justify-content-end">

                                    <button disabled={loading} type="submit" className="btn btn-primary">
                                        {loading && <Spinner size="sm" className="mx-1" />}
                                        Submit</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
// Actions
import { getCoinsListApi, getSettings, putMobileSettings, updateLeaderboardSettings, updateSettings } from "store/actions";
import { Row, Col, FormGroup, Label, Input, Button, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./Instructions";
import Select from 'react-select';
import { Alert } from "react-bootstrap";
import { checkPermissions } from "helpers/common";

const LeaderboardSettings = () => {
  // const  t =useTransition();
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(({ Settings }) => Settings);
  const { coinsList, loadingCoins } = useSelector(({ Game }) => Game);

  const [options, setOptions] = useState([]);
  const { user } = useSelector((state) => state.Auth);
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false
  })

  useEffect(() => {
    if (user?.permissions) {
      setPermissions({
        add: false,
        edit: checkPermissions(["edit_leaderboard_settings"], user?.permissions),
        delete: false
      })
    }
  }, [user]);

  useEffect(() => {
    dispatch(getCoinsListApi({}));
  }, []);

  useEffect(() => {
    let data = [];
    if (coinsList?.length) {
      data = (coinsList).map((item) => ({ label: item.symbol, value: item.symbol }));

    }
    setOptions(data);
  }, [coinsList]);

  const findDefaultOption = (value) => {
    try {
      return options.find(option => option.value === value);
    } catch {
      return null;
    }
  };

  const leaderboardIconKey = {
    "leaderboardPrizeTypeForRank1": "leaderboardPrizeIconForRank1",
    "leaderboardPrizeTypeForRank2": "leaderboardPrizeIconForRank2",
    "leaderboardPrizeTypeForRank3": "leaderboardPrizeIconForRank3",
    "leaderboardPrizeTypeForRank4": "leaderboardPrizeIconForRank4",
    "leaderboardPrizeTypeForRank5": "leaderboardPrizeIconForRank5",
    "leaderboardPrizeTypeForRank6": "leaderboardPrizeIconForRank6",
    "leaderboardPrizeTypeForRank7": "leaderboardPrizeIconForRank7",
    "leaderboardPrizeTypeForRank8": "leaderboardPrizeIconForRank8",
    "leaderboardPrizeTypeForRank9": "leaderboardPrizeIconForRank9",
    "leaderboardPrizeTypeForRank10": "leaderboardPrizeIconForRank10",
    // "leaderboardPrizeTypeForRank11": "leaderboardPrizeIconForRank11",
    // "leaderboardPrizeTypeForRank12": "leaderboardPrizeIconForRank12",
    // "leaderboardPrizeTypeForRank13": "leaderboardPrizeIconForRank13",
    // "leaderboardPrizeTypeForRank14": "leaderboardPrizeIconForRank14",
    // "leaderboardPrizeTypeForRank15": "leaderboardPrizeIconForRank15"
  }


  const [fields, setFields] = useState(
    {
      "leaderboard": {
        "leaderboardStatus": "active",
        "leaderboardPrizeForRank1": 0,
        "leaderboardPrizeTypeForRank1": "btnc",
        "leaderboardPrizeIconForRank1": "",
        "leaderboardPrizeForRank2": 0,
        "leaderboardPrizeTypeForRank2": "btnc",
        "leaderboardPrizeIconForRank2": "",
        "leaderboardPrizeForRank3": 0,
        "leaderboardPrizeTypeForRank3": "btnc",
        "leaderboardPrizeIconForRank3": "",
        "leaderboardPrizeForRank4": 0,
        "leaderboardPrizeTypeForRank4": "btnc",
        "leaderboardPrizeIconForRank4": "",
        "leaderboardPrizeForRank5": 0,
        "leaderboardPrizeTypeForRank5": "btnc",
        "leaderboardPrizeIconForRank5": "",
        "leaderboardPrizeForRank6": 0,
        "leaderboardPrizeTypeForRank6": "btnc",
        "leaderboardPrizeIconForRank6": "",
        "leaderboardPrizeForRank7": 0,
        "leaderboardPrizeTypeForRank7": "btnc",
        "leaderboardPrizeIconForRank7": "",
        "leaderboardPrizeForRank8": 0,
        "leaderboardPrizeTypeForRank8": "btnc",
        "leaderboardPrizeIconForRank8": "",
        "leaderboardPrizeForRank9": 0,
        "leaderboardPrizeTypeForRank9": "btnc",
        "leaderboardPrizeIconForRank9": "",
        "leaderboardPrizeForRank10": 0,
        "leaderboardPrizeTypeForRank10": "btnc",
        "leaderboardPrizeIconForRank10": "",
        "leaderboardPointfor10thLine": 0,
        // "leaderboardPrizeForRank11": 0,
        // "leaderboardPrizeTypeForRank11": "btnc",
        // "leaderboardPrizeIconForRank11": "",
        "leaderboardPointfor11thLine": 0,
        // "leaderboardPrizeForRank12": 0,
        // "leaderboardPrizeTypeForRank12": "btnc",
        // "leaderboardPrizeIconForRank12": "",
        "leaderboardPointfor12thLine": 0,
        // "leaderboardPrizeForRank13": 0,
        // "leaderboardPrizeTypeForRank13": "btnc",
        // "leaderboardPrizeIconForRank13": "",
        "leaderboardPointfor13thLine": 0,
        // "leaderboardPrizeForRank14": 0,
        // "leaderboardPrizeTypeForRank14": "btnc",
        // "leaderboardPrizeIconForRank14": "",
        "leaderboardPointfor14thLine": 0,
        // "leaderboardPrizeForRank15": 0,
        // "leaderboardPrizeTypeForRank15": "btnc",
        // "leaderboardPrizeIconForRank15": "",
        "leaderboardPointfor15thLine": 0,
      }
    }
  );

  useEffect(() => {
    dispatch(getSettings());
  }, [])

  useEffect(() => {
    if (settings?._id) {
      console.log("settings", settings)
      setFields((prevState) => ({
        ...prevState,
        game: settings?.game,
        leaderboard: settings?.leaderboard,
        energy: settings?.energy
      }));
    }
  }, [JSON.stringify(settings)]);


  const handleChange = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: Number(value) }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };

  const handleChangeString = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: value }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };


  const handleSelectChange = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;

    const icon = coinsList?.find((item) => item?.symbol === value)?.icon;
    if (!icon) return;
    const getIconKey = leaderboardIconKey[name];
    if (!getIconKey) return;
    let copySettings = { ...fields };
    let data = {
      [name]: value,
      [getIconKey]: icon
    };
    console.log("data", data);
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], ...data }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };


  function submit() {
    dispatch(
      updateLeaderboardSettings({
        data: fields.leaderboard
      }));
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={12} lg={12}>
              <Card>
                <CardBody className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  {loading && <div className="spinner"></div>}

                  <Col lg={8}>
                    <Instructions />
                  </Col>

                  <Col lg={8}>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 15th line</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPointfor15thLine}
                            name="leaderboardPointfor15thLine"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 15th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank15}
                            name="leaderboardPrizeForRank15"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank15)}
                            name="leaderboardPrizeTypeForRank15"
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank15", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col> */}

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 14th line</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPointfor14thLine}
                            name="leaderboardPointfor14thLine"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      {/* <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 14th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank14}
                            name="leaderboardPrizeForRank14"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank14)}
                            name="leaderboardPrizeTypeForRank14"
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank14", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col> */}

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 13th line</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPointfor13thLine}
                            name="leaderboardPointfor13thLine"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      {/* <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 13th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank13}
                            name="leaderboardPrizeForRank13"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank13)}
                            name="leaderboardPrizeTypeForRank13"
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank13", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col> */}

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 12th line</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPointfor12thLine}
                            name="leaderboardPointfor12thLine"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      {/* 
                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 12th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank12}
                            name="leaderboardPrizeForRank12"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank12)}
                            name="leaderboardPrizeTypeForRank12"
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank12", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col> */}

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 11th line</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPointfor11thLine}
                            name="leaderboardPointfor11thLine"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      {/* <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 11th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank11}
                            name="leaderboardPrizeForRank11"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank11)}
                            name="leaderboardPrizeTypeForRank11"
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank11", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col> */}


                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Point for 10th line</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPointfor10thLine}
                            name="leaderboardPointfor10thLine"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      {/* <Col md={6} /> */}

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 10th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank10}
                            name="leaderboardPrizeForRank10"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank10)}
                            name="leaderboardPrizeTypeForRank10"
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank10", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 9th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank9}
                            name="leaderboardPrizeForRank9"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank9)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank9", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank9"
                            id="rank9Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 8th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank8}
                            name="leaderboardPrizeForRank8"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank8)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank8", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank8"
                            id="rank8Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 7th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank7}
                            name="leaderboardPrizeForRank7"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank7)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank7", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank7"
                            id="rank7Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 6th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank6}
                            name="leaderboardPrizeForRank6"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />


                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank6)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank6", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank6s"
                            id="rank6Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 5th rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank5}
                            name="leaderboardPrizeForRank5"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank5)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank5", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank5"
                            id="rank5Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 4th rank</Label>

                          <Input
                            type="number"
                            name="leaderboardPrizeForRank4"
                            value={fields?.leaderboard?.leaderboardPrizeForRank4}
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank4)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank4", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank4"
                            id="rank4Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 3rd rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank3}
                            name="leaderboardPrizeForRank3"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                          <Select
                            className="mt-3 text-muted"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank3)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank3", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank3"
                            id="rank3Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 2nd rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank2}
                            name="leaderboardPrizeForRank2"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank2)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank2", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank2"
                            id="rank2Type"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Prize for 1st rank</Label>

                          <Input
                            type="number"
                            value={fields?.leaderboard?.leaderboardPrizeForRank1}
                            name="leaderboardPrizeForRank1"
                            onChange={
                              (e) => handleChange("leaderboard")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.leaderboard?.leaderboardPrizeTypeForRank1)}
                            onChange={
                              (e) => handleSelectChange("leaderboard")({ target: { name: "leaderboardPrizeTypeForRank1", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select type..."
                            name="leaderboardPrizeTypeForRank1"
                            id="rank1Type"
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Leaderboard Status</Label>

                          {/* <div className="status-switch square-switch">
                            <input
                              type="checkbox"
                              id="square-switch-leaderboaraasas"
                              switch="none"
                              checked={fields?.leaderboard?.leaderboardStatus === "active"}
                              onChange={() => {
                                const value =
                                  fields?.leaderboard?.leaderboardStatus === "active" ? "inactive" : "active";
                                console.log("value", value);

                                () => handleChangeString("leaderboard")({ target: { name: "leaderboardStatus", value } });
                              }}
                            />
                            <label
                              htmlFor="square-switch-leaderboardStatus"
                              data-on-label={"Active"}
                              data-off-label={"Inactive"}
                            />
                          </div> */}
                          <div className="d-flex align-items-center gap-10">
                            <input type="checkbox"
                              checked={fields?.leaderboard?.leaderboardStatus === "active" ? true : false}
                              id="activeInactive" className="form-check" onClick={() => {
                                const value =
                                  fields?.leaderboard?.leaderboardStatus === "active" ? "inactive" : "active";
                                console.log("value", value);

                                handleChangeString("leaderboard")({ target: { name: "leaderboardStatus", value: value } });
                              }}
                            />
                            <label htmlFor="activeInactive" className="form-lable m-0">Active</label>
                          </div>

                        </FormGroup>
                      </Col>

                      <Col md={6} />
                      {permissions.edit ?
                        <Col className="d-flex justify-content-end">
                          {loading && <Spinner color="primary" className="mr-2" />}

                          <Button className="btn btn-primary" onClick={submit}>
                            Save Changes
                          </Button>
                        </Col>
                        : null}
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

export default LeaderboardSettings;
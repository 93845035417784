const inputFields = [
  {
    name: 'email',
    type: 'email',
    label: 'Email*',
    placeholder: '',
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password*',
    placeholder: '',
  },
  {
    name: 'twoFACode',
    type: 'text',
    label: 'Two Factor Auth Code*',
    placeholder: '',
  },

];

export default inputFields;
import { createAsyncThunk } from "@reduxjs/toolkit";

import * as URL from "helpers/url_helper";
import { get, post } from "helpers/api_helper";
import { toast } from "react-toastify";
import { getValuesSocketData } from "helpers/secretManager";

export const getSettings = createAsyncThunk(
  "getSettings",
  async (payload, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await get(URL.GET_SETTINGS, { signature, nonce });

      return response.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "updateSetting",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.UPDATE_SETTINGS, data, { signature, nonce });
      if (response?.status === "failure") {
        toast.error(response?.message || "Something went wrong!")

        return callback && callback(response?.message, null);

      }
      callback && callback(null, response.data);
      toast.success("Settings updated successfully!")
      return response.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

//settings

export const updateGameSettings = createAsyncThunk(
  "updateGameSettings",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.UPDATE_GAME_SETTING, data, { signature, nonce });
      if (response?.status === "failure") {
        toast.error(response?.message || "Something went wrong!")

        return callback && callback(response?.message, null);

      }
      callback && callback(null, response.data);
      toast.success("Settings updated successfully!")
      return response.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const updateEnergySettings = createAsyncThunk(
  "updateEnergySettings",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.UPDATE_ENERGY_SETTING, data, { signature, nonce });
      if (response?.status === "failure") {
        toast.error(response?.message || "Something went wrong!")

        return callback && callback(response?.message, null);

      }
      callback && callback(null, response.data);
      toast.success("Settings updated successfully!")
      return response.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);


export const updateLeaderboardSettings = createAsyncThunk(
  "updateLeaderboardSettings",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.UPDATE_LEADERBOARD_SETTING, data, { signature, nonce });
      if (response?.status === "failure") {
        toast.error(response?.message || "Something went wrong!")

        return callback && callback(response?.message, null);

      }
      callback && callback(null, response.data);
      toast.success("Settings updated successfully!")
      return response.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateMaintenanceSettings = createAsyncThunk(
  "updateMaintenanceSettings",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.UPDATE_MAINTENANCE_SETTING, data, { signature, nonce });
      if (response?.status === "failure") {
        toast.error(response?.message || "Something went wrong!")

        return callback && callback(response?.message, null);

      }
      callback && callback(null, response.data);
      toast.success("Settings updated successfully!")
      return response.data;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!")
      callback && callback(error);
      return Thunk.rejectWithValue(error);
    }
  }
);
const inputFields = [
  {
    name: "points",
    type: "text",
    label: "Points",
    placeholder: "Points...",
  }
];

export default inputFields;

import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";

const initialState = {
  loading: false,
  roadmapsList: [],
  totalCount: 0,
};

const slice = createSlice({
  name: "Roadmaps",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Act.getRoadmapsList.pending, (state, action) => ({
      ...state,
      loading: true,
    }));
    builder.addCase(Act.getRoadmapsList.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      roadmapsList: payload.data,
      totalCount: payload.totalcount,
    }));
    builder.addCase(Act.getRoadmapsList.rejected, (state, action) => ({
      ...state,
      loading: false,
      roadmapsList: [],
      totalCount: 0,
    }));


    //add faq 
    builder.addCase(Act.addRoadmapApi.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.addRoadmapApi.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
        roadmapsList: [payload.data, ...state.roadmapsList],
        totalCount: state.totalCount + 1
      }));
    builder.addCase(Act.addRoadmapApi.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //edit FAQ 
    builder.addCase(Act.editRoadmapApi.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.editRoadmapApi.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
        roadmapsList: state.roadmapsList.map((item) => item?._id === payload?.data?._id ? payload?.data : item)
      }));
    builder.addCase(Act.editRoadmapApi.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

    //delete FAQ 
    builder.addCase(Act.deleteRoadmap.pending, (start, action) => ({
      ...start,
      loading: true,
    }));
    builder.addCase(Act.deleteRoadmap.fulfilled, (state, { payload }) => (
      console.log(payload),
      {
        ...state,
        loading: false,
        roadmapsList: state.roadmapsList.filter((item) => item?._id !== payload?.data?._id),
        totalCount: state.totalCount > 1 ? state.totalCount - 1 : 0
      }));
    builder.addCase(Act.deleteRoadmap.rejected, (state, action) => ({
      ...state,
      loading: false,
    }));

  },
});

export default slice;

import { DATE_FORMAT } from "helpers/common";
import React from "react";
import { Link } from "react-router-dom";
import { Stack, Badge } from "react-bootstrap";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })

const STATUS = {
  "active": "Active",
  "inactive": "Inactive",
  "blocked": "Blocked",
  "deleted": "Deleted"
}

const Columns = ({ handleUserStatus, handleEditHandler, deleteHandler }) => [
  {
    dataField: "name",
    text: "Name",
    formatter: (_, row) => <span className="text-capitalize">{row.name}</span>,
  },
  {
    dataField: "email",
    text: "Email",
    formatter: (_, row) => <span className="text-capitalize">{row.email}</span>,
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => row.createdAt && DATE_FORMAT(row.createdAt),
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },
  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    text: "Permissions",
    dataField: "permissions",
    formatter: (cellContent, row) => (
      <Stack direction="horizontal" className="d-flex flex-wrap" gap={2}>
        {row?.permissions?.map((permission, index) => {
          return (
            <>
              <Badge key={index} pill bg="primary">
                {permission}
              </Badge>
            </>
          )
        })}
      </Stack>
    ),
  },
  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
        {row?.status && (row?.status === "active") ? (
          <button
            className="fas fa-toggle-on"
            style={{
              fontSize: "20px",
              color: "green",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Active"
            onClick={() => handleUserStatus(row._id, "blocked")}
          ></button>
        ) : (
          <button
            className="fas fa-toggle-off"
            style={{
              fontSize: "20px",
              color: "red",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Blocked"
            onClick={() => handleUserStatus(row._id, "active")}
          ></button>
        )}

        <button
          className="fas fa-pen"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() =>
            handleEditHandler(row)
          }
        ></button>
        <button
          className="fas fa-trash-alt"
          style={{
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Inactive"
          onClick={() => deleteHandler(row._id)}
        ></button>
      </>
    ),
  },
];
export default Columns;

import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import Table from "components/Table";
import Columns from "./Columns";
import { get } from "helpers/api_helper";
import { REMAINING_CRYPTO } from "helpers/url_helper";
import { getSocketEmit, getValuesSocketData } from "helpers/secretManager";
import useSocket from "hooks/useSocket";

// const InputFieldsForSearch = [
//   {
//     name: "search",
//     type: "text",
//     label: "",
//     placeholder: "Search...",
//   },
//   {
//     name: "datepicker",
//     type: "datepicker",
//     label: "",
//     placeholder: "",
//   },
// ];
// const initialValuesForFilter = {
//   search: "",
// };

const List = () => {
  const { socket, socketConnect } = useSocket();

  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (socketConnect) {
      getApiData();
    }
    setcolumns(Columns({}));
  }, [socketConnect]);

  async function getApiData() {
    try {
      // const { signature, nonce } = await getValuesSocketData();
      setLoading(true);
      // const response = await get(`${REMAINING_CRYPTO}`, { signature, nonce });
      // console.log("response", response)
      // if (response?.status === 'success') {
      //   setData(response?.data);
      // }
      const response = await getSocketEmit(socket, "getStatisticsData", { key: "remainingCryptoList" });
      if (response?.status && typeof response?.data !== "string") {
        setData(response?.data || []);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }

  const handleFilterForm = (info) => {

  };


  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}

      <section className="section">

        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">Remaining Crypto</h2>
                </div>
              </div>
            </div>
          </div>
          {data?.length > 0 && (
            <Table
              data={data}
              columns={columns}
              setFilter={() => console.log()}
              total={100}
              filter={{
                page: 1,
                limit: 100,
                order: -1,
                orderBy: "line",
              }}
            />
          )}
          {!loading && data?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Record Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;

import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { addTaskApi, logsListApi, tasksListApi, updateTaskApi } from "store/actions";
import Table from "components/Table";
import Columns from "./Columns";
import FormikForm from "components/shared/FormikForm";
const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search...",
  },
  {
    name: "type",
    type: "select",
    label: "",
    placeholder: "Select type",
    options: [
      { value: "", label: "All" },
      // { value: "login", label: "Login" },
      // { value: "buyEnergy", label: "Buy Energy" },
      // { value: "startedGame", label: "Started Game" },
      // { value: "completedGame", label: "Completed Game" },
      // { value: "fetchBalance", label: "Fetch Balance" },
      // { value: "completedTask", label: "Completed Task" },
      // { value: "linkedBitanica", label: "Linked Bitanica" },
      // { value: "claimedCoin", label: "Claimed Coin" },
      // { value: "energyRewarded", label: "Energy Rewarded" },
      // { value: "transferCoin", label: "Transfer Coin" },
      // { value: "endedGame", label: "Ended Game" },
      // { value: "updatedGameSettings", label: "Updated Game Settings" },
      // { value: "updatedEnergySettings", label: "Updated Energy Settings" },
      // { value: "updatedLeaderboardSettings", label: "Updated Leaderboard Settings" },
      // { value: "updatedMaintenanceSettings", label: "Updated Maintenance Settings" },
      // { value: "exportedUserData", label: "Exported User Data" },
      // { value: "updatedLeaderboard", label: "Updated Leaderboard" },
      // { value: "updatedTask", label: "Updated Task" },
      // { value: "createdTask", label: "Created Task" },
      // { value: "deletedTask", label: "Deleted Task" },
      // { value: "changedPassword", label: "Changed Password" },
      // { value: "exportedGameHistory", label: "Exported Game History" },
      // { value: "exportedClaimHistory", label: "Exported Claim History" },
      // { value: "createdRoadMap", label: "Created Road Map" },
      // { value: "updatedRoadMap", label: "Updated Road Map" },
      // { value: "completedRoadMap", label: "Completed Road Map" },
      // { value: "deletedRoadMap", label: "Deleted Road Map" },
      { value: "updateBTNCBalance", label: "Update BTNC Balance" },
      { value: "updateEnergyBalance", label: "Update Energy Balance" },
    ]
  },
];
const initialValuesForFilter = {
  search: "",
  type: "updateEnergyBalance",
};

const List = () => {
  const dispatch = useDispatch();
  const { logsList, totalLogs, loading } = useSelector((state) => state.Tasks);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [filter, setFilter] = useState({
    pageNo: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    type: "updateEnergyBalance"
  });

  useEffect(() => {

    getApiData(filter);
    setcolumns(Columns({}));

  }, [filter]);

  async function getApiData(query) {
    try {
      dispatch(logsListApi({ data: query }));

    } catch (error) { }
  }

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      pageNo: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      type: info.type,
    });
  };


  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      <Helmet title={"Logs"} />
      <section className="section">
        <div className="section-header">
          <h1>{"Logs"}</h1>

          {/* <div className="section-header-breadcrumb">
          <div className="breadcrumb-item active">
            <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
          </div>
          <div className="breadcrumb-item">{"Logs"} </div>
        </div> */}
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">{"Logs"}</h2>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                  />
                </div>
              </div>

            </div>
          </div>
          {logsList?.length > 0 && (
            <Table
              data={logsList}
              columns={columns}
              setFilter={setFilter}
              total={totalLogs}
              filter={filter}
            />
          )}
          {!loading && logsList?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Logs Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;

import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })

const STATUS = {
  "active": "Active",
  "inactive": "Inactive",
  "blocked": "Blocked",
  "deleted": "Deleted"
}

const Columns = ({ handleUserStatus, handleEditHandler }) => [
  {
    dataField: "username",
    text: "ID",
    formatter: (_, row) => <span className="text-capitalize">{row?.userDetails?.username}</span>,
  },
  {
    dataField: "Username",
    text: "Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.userDetails?.telegram_username ? row?.userDetails?.telegram_username : row?.userDetails?.first_name}</span>,
  },
  {
    dataField: "coinName",
    text: "Coin Name",
    formatter: (_, row) => <span className="text-capitalize">{row?.coinName}</span>,
  },
  {
    dataField: "coinSymbol",
    text: "Coin Symbol",
    formatter: (_, row) => <span className="text-capitalize">{row?.coinSymbol}</span>,
  },
  {
    dataField: "taskDetail",
    text: "Task Id",
    formatter: (_, row) => <span className="text-capitalize">{row?.taskDetails?.taskID}</span>,
  },
  {
    dataField: "amount",
    text: "Amount",
    formatter: (_, row) => <span className="text">{row?.amount}</span>,
  },
  {
    dataField: "status",
    text: "Status",
    formatter: (_, row) => <span className="text">{row?.status}</span>,
  },
  {
    dataField: "date_created_utc",
    text: "Created At",
    formatter: (_, row) => <span className="text-capitalize">{row.date_created_utc && DATE_FORMAT(row.date_created_utc)}</span>,
  },
  {
    dataField: "claimedAt",
    text: "Claimed At",
    formatter: (_, row) => <span className="text-capitalize">{row.claimedAt && DATE_FORMAT(row.claimedAt)}</span>,
  },
  {
    dataField: "rejectedAt",
    text: "Rejected At",
    formatter: (_, row) => <span className="text-capitalize">{row?.rejectedAt && DATE_FORMAT(row?.rejectedAt)}</span>,
  },
];
export default Columns;

import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { addSubAdminApi, updateAdmin } from "store/actions";
import { useDispatch } from "react-redux";

const permissionsList = [
    "profile",
    "change_password",
    "statistics",
    "manage_users",
    "game_history",
    "leaderboard",
    "claim_history",
    "logs",
    "tasks",
    "roadmap",
    "add_roadmap",
    "edit_roadmap",
    "delete_roadmap",
    "add_task",
    "edit_task",
    "delete_task",
    "edit_leaderboard_points",
    "edit_game_settings",
    "edit_leaderboard_settings",
    "edit_energy_settings",
    "edit_maintenance_settings",
    "edit_users",
    "game_settings",
    "leaderboard_settings",
    "energy_settings",
    "maintenance_settings"
];

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    permissions: Yup.array().of(Yup.string()).required('At least one permission is required'),
    // status: Yup.string().required('Status is required'),
});

const editvalidationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    permissions: Yup.array().of(Yup.string()).required('At least one permission is required'),
    // status: Yup.string().required('Status is required'),
});

export default function AddSubAdmin({
    show,
    handleClose,
    handleConfirm,
    rowData
}) {

    const [initialValues, setInitialValues] = useState({
        name: '',
        email: '',
        password: '',
        permissions: [],
        // status: 'active',
    });

    useEffect(() => {
        if (rowData) {
            setInitialValues({
                name: rowData?.name,
                email: rowData?.email,
                password: rowData?.password,
                permissions: rowData?.permissions,
                // status: 'active',
            })
        }
    }, [rowData])
    const dispatch = useDispatch();

    const onSubmit = (values) => {
        if (rowData) {
            const payload = { ...values, _id: rowData?._id }
            delete payload.email;
            delete payload.password;
            dispatch(updateAdmin({
                data: payload, callBack: (err, res) => {
                    if (err) return;
                    if (res) {
                        handleConfirm();
                        handleClose();
                    }
                }
            }))
        } else {
            dispatch(addSubAdminApi({
                data: values, callback: (err, res) => {
                    if (err) return;
                    if (res) {
                        handleConfirm();
                        handleClose();
                    }
                }
            }))
        }
    }

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            size="lg"
        >
            <Modal.Header className="py-3 text-dark" closeButton>
                <Modal.Title className="fw-bold text-dark">Add Sub Admin</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        console.log('Form data:', values);
                        onSubmit(values);
                    }}
                >
                    {({ values, setFieldValue }) => {
                        const addPermission = (permission) => {
                            if (permission && !values.permissions.includes(permission)) {
                                setFieldValue('permissions', [...values.permissions, permission]);
                            }
                        };

                        const removePermission = (permission) => {
                            setFieldValue(
                                'permissions',
                                values.permissions.filter((perm) => perm !== permission)
                            );
                        };

                        return (
                            <Form>
                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="name" className="form-label">Name</label>
                                        <Field name="name" type="text" className="form-control" />
                                        <ErrorMessage name="name" component="div" className="text-danger mt-1" />
                                    </div>

                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="email" className="form-label">Email</label>
                                        <Field name="email" type="email" className="form-control" />
                                        <ErrorMessage name="email" component="div" className="text-danger mt-1" />
                                    </div>
                                </div>

                                {!rowData ?
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="password" className="form-label">Password</label>
                                            <Field name="password" type="password" className="form-control" />
                                            <ErrorMessage name="password" component="div" className="text-danger mt-1" />
                                        </div>


                                    </div>
                                    : null}

                                <div className="row">
                                    <div className="col-md-6 mb-3">
                                        <label htmlFor="permissions" className="form-label">Permissions</label>
                                        <Field
                                            as="select"
                                            name="permission"
                                            className="form-select"
                                            onChange={(e) => {
                                                addPermission(e.target.value);
                                                e.target.value = '';
                                            }}
                                        >
                                            <option value="">Select a permission</option>
                                            {permissionsList
                                                .filter((perm) => !values.permissions.includes(perm))
                                                .map((perm) => (
                                                    <option key={perm} value={perm}>
                                                        {perm}
                                                    </option>
                                                ))}
                                        </Field>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <div className="d-flex flex-wrap gap-2">
                                        {values.permissions.map((permission) => (
                                            <span
                                                key={permission}
                                                className="badge bg-primary d-flex align-items-center"
                                                style={{ padding: '0.5rem 1rem', fontSize: '0.875rem' }}
                                            >
                                                {permission}
                                                <button
                                                    type="button"
                                                    className="btn-close btn-sm ms-2"
                                                    aria-label="Remove"
                                                    onClick={() => removePermission(permission)}
                                                    style={{ fontSize: '0.75rem' }}
                                                >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </span>
                                        ))}
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </Modal.Body>
        </Modal>
    );
}

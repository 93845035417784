import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { earningsListApi } from "store/actions";
import Table from "components/Table";
import Columns from "./Columns";
// import FormikForm from "components/shared/FormikForm";



const InputFieldsForSearch = [
  // {
  //   name: "search",
  //   type: "text",
  //   label: "",
  //   placeholder: "Search...",
  // },
  {
    name: "level",
    type: "select",
    label: "",
    placeholder: "Select level",
    options: [
      { value: 1, label: "Level 1" },
      { value: 2, label: "Level 2" },
    ],
  },
];
const initialValuesForFilter = {
  // search: "",
  level: 1
};

const List = ({ userDetails }) => {
  const dispatch = useDispatch();
  const { loadingEarningsList, earningsList, totalEarningsList } = useSelector(({ Users }) => Users);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "date_created_utc",
    search: "",
    userId: userDetails?._id,
  });

  useEffect(() => {
    if (userDetails?._id) {
      getApiData({ ...filter, userId: userDetails?._id });
      setcolumns(Columns({}));
    }
  }, [filter, userDetails]);

  async function getApiData(query) {
    try {
      dispatch(earningsListApi({ data: query }));
    } catch (error) { }
  }

  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      // level: info.level
    });
  };


  return (
    <>
      <div className="section-body">
        {/* <div className="card">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="card-header">
                <FormikForm
                  initialValues={initialValuesForFilter}
                  onSubmit={handleFilterForm}
                  loading={loadingRefferalList}
                  inputFields={InputFieldsForSearch}
                  buttonText={"Search"}
                />
              </div>
            </div>

          </div>
        </div> */}
        {earningsList?.length > 0 && (
          <Table
            data={earningsList}
            columns={columns}
            setFilter={setFilter}
            total={totalEarningsList}
            filter={filter}
          />
        )}
        {!loadingEarningsList && earningsList?.length === 0 && (
          <p style={{ textAlign: "center" }}>No Data Found</p>
        )}
      </div>
    </>
  );
};

export default List;

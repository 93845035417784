import React from "react"
//imported
import * as Path from "../Path"

//dashboard
import Dashboard from "views/dashboard/Dashboard"

//User
import UserList from "views/users/List"
import Login from "views/auth/Login/Login"
import ForgetPassword from "views/auth/Forget-password/ForgotPassword"
import GeneralSettings from "views/settings/General/index"
import Profile from "views/auth/profile/index"
import ChangePassword from "views/auth/change_password/index"
import MGameSettings from "views/settings/Mini-Game";
import EnerygySetting from "views/settings/Energy";
import LeaderboardSettings from "views/settings/Leaderboard";
import MaintenanceSettings from "views/settings/Maintenance"
import LanguageList from "views/language/List";

//game
import GameHistoryList from "views/gameHistory/List"
import GameHistoryView from "views/gameHistory/View"
import GameLeaderboard from "views/gameLeaderboard/List"
import ClaimHistory from "views/claimHistory/List"
import UserView from "views/users/View"
import TasksListPage from "views/tasks/List"
import RoadmapList from "views/roadmap/List"
import Statistics from "views/Statistics"
import Logs from "views/Logs/List"
import SubAdminsList from "views/subadmins/List"
import SubAdminView from "views/subadmins/View"



const routes = [
  {
    path: Path.statistics,
    exact: true,
    auth: false,
    component: <Statistics />,
    permission: ["statistics"],
    is_permission: true,
  },
  {
    path: Path.dashboard,
    exact: true,
    auth: false,
    component: <Dashboard />,
    permission: ["dashboard"],
    is_permission: true,
  },
  {
    path: Path.users,
    exact: true,
    auth: true,
    component: <UserList />,
    permission: ["manage_users", "edit_users"],
    is_permission: true,
  },
  {
    path: Path.usersView,
    exact: true,
    auth: true,
    component: <UserView />,
    permission: ["manage_users", "edit_users"],
    is_permission: true,
  },
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    component: <Login />,
    is_permission: false,
  },
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: <ForgetPassword />,
    is_permission: false,
  },
  {
    path: Path.changePassword,
    exact: true,
    auth: false,
    component: <ChangePassword />,
    permission: ["change_password"],
    is_permission: true,
  },
  {
    path: Path.game_setting,
    exact: true,
    auth: false,
    component: <MGameSettings />,
    permission: ["game_settings", "edit_game_settings"],
    is_permission: true,
  },
  {
    path: Path.energy_setting,
    exact: true,
    auth: false,
    component: <EnerygySetting />,
    permission: ["energy_settings", "edit_energy_settings"],
    is_permission: true,
  },
  {
    path: Path.leaderboard_setting,
    exact: true,
    auth: false,
    component: <LeaderboardSettings />,
    permission: ["leaderboard_settings", "edit_leaderboard_settings"],
    is_permission: true,
  },
  {
    path: Path.maintenance_setting,
    exact: true,
    auth: false,
    component: <MaintenanceSettings />,
    permission: ["maintenance_settings", "edit_maintenance_settings"],
    is_permission: true,
  },
  // {
  //   path: Path.general_setting,
  //   exact: true,
  //   auth: false,
  //   component: <GeneralSettings />,
  // },

  {
    path: Path.profile,
    exact: true,
    auth: false,
    component: <Profile />,
    permission: ["profile"],
    is_permission: true,
  },

  {
    path: Path.language,
    exact: true,
    auth: false,
    component: <LanguageList />,
    permission: ["languages"],
    is_permission: true,
  },
  {
    path: Path.gameHisory,
    exact: true,
    auth: false,
    component: <GameHistoryList />,
    permission: ["game_history"],
    is_permission: true,
  }, {
    path: Path.gameHisoryView,
    exact: true,
    auth: false,
    component: <GameHistoryView />,
    permission: ["game_history"],
    is_permission: true,
  }, {
    path: Path.gameLeaderboard,
    exact: true,
    auth: false,
    component: <GameLeaderboard />,
    permission: ["leaderboard", "edit_leaderboard_points"],
    is_permission: true,
  }, {
    path: Path.claimHistory,
    exact: true,
    auth: false,
    component: <ClaimHistory />,
    permission: ["claim_history"],
    is_permission: true,
  },
  {
    path: Path.tasksList,
    exact: true,
    auth: false,
    component: <TasksListPage />,
    permission: ["tasks", "add_task", "edit_task", "delete_task"],
    is_permission: true,
  },
  {
    path: Path.roadmapsList,
    exact: true,
    auth: false,
    component: <RoadmapList />,
    permission: ["roadmap", "add_roadmap", "edit_roadmap", "delete_roadmap"],
    is_permission: true,
  },
  {
    path: Path.logs,
    exact: true,
    auth: true,
    component: <Logs />,
    permission: ["logs"],
    is_permission: true,
  },
  {
    path: Path.subadmins,
    exact: true,
    auth: true,
    component: <SubAdminsList />,
    permission: ["subadmins"],
    is_permission: true,
  },
  {
    path: Path.subAdminView,
    exact: true,
    auth: true,
    component: <SubAdminView />,
    permission: ["subadmins"],
    is_permission: true,
  },
]

export default routes
import React, { useState, useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { get } from "helpers/api_helper";
import { GET_STATISTICS_LINE_COUNT } from "helpers/url_helper";
import { getSocketEmit, getValuesSocketData } from "helpers/secretManager";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis, Tooltip, LabelList } from "recharts";
import FormikForm from "components/shared/FormikForm";
import moment from "moment";
import { formatNumber } from "helpers/common";
import useSocket from "hooks/useSocket";



const InputFieldsForSearch = [
    {
        name: "datepicker",
        type: "datepicker",
        label: "",
        placeholder: "",
    }
];

const initialValuesForFilter = {
    startDate: moment(),
    endDate: moment(),
};

const StatisticsLinesCount = () => {
    const { socket, socketConnect } = useSocket();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showPicker, setShowPicker] = useState(false);
    const [dateFilter, setDateFilter] = useState({
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
    });
    const [filter, setFilter] = useState({
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
    });

    const handleFilterForm = (info) => {
        console.log("info", info)
        if (!dateFilter.startDate || !dateFilter.endDate) {
            return;
        }
        setFilter({
            ...filter,
            startDate: dateFilter.startDate,
            endDate: dateFilter.endDate
        });
    };

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection;
        const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
        const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
        setDateFilter((prevFilter) => ({
            ...prevFilter,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
        }));
        setShowPicker(false);
    };


    useEffect(() => {
        if (socketConnect) {
            getApiData(filter);
        }
    }, [filter, socketConnect]);

    async function getApiData(queryData) {
        try {
            // const { signature, nonce } = await getValuesSocketData();
            setLoading(true);
            // let url = `${GET_STATISTICS_LINE_COUNT}`;
            // if (queryData?.startDate && queryData?.endDate) {
            //     url = url + `/?startDate=${queryData?.startDate}&endDate=${queryData?.endDate}`
            // }
            const response = await getSocketEmit(socket, "getStatisticsData", { key: "lineCountData", startDate: queryData?.startDate, endDate: queryData?.endDate });
            // const response = await get(`${url}`, { signature, nonce });
            if (response?.status && typeof response?.data !== "string") {
                setData(response?.data);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            // Sort the payload data in ascending order by value
            // const sortedPayload = [...payload].sort((a, b) => a.value - b.value);
            const sortedPayload = [...payload].sort((a, b) => {
                if (a.name === 'rounds' && b.name !== 'rounds') {
                    return -1; // Place "rounds" at the top
                }
                if (a.name !== 'rounds' && b.name === 'rounds') {
                    return 1; // Keep "rounds" at the top
                }
                return b.value - a.value; // Ascending sort for other items
            });

            return (
                <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '10px', border: '1px solid #ccc' }}>
                    <p className="label">{`${label}`}</p>
                    {sortedPayload.map((entry, index) => (
                        <p key={`item-${index}`}>{`${entry.name}: ${formatNumber(entry.value)}`}</p>
                    ))}
                </div>
            );
        }

        return null;
    };

    return (
        <>
            {loading && (
                <div className="main-loader">
                    <FadeLoader size={1000} />
                </div>
            )}

            <section className="section">

                <div className="section-body">
                    <div className="card">
                        <div className="row justify-content-between">
                            <div className="col-6">
                                <div className="card-header">
                                    <h2 className="section-title">Game Lines Opened</h2>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                <FormikForm
                                    initialValues={initialValuesForFilter}
                                    onSubmit={handleFilterForm}
                                    loading={loading}
                                    inputFields={InputFieldsForSearch}
                                    buttonText={"Search"}
                                    dateRange={dateFilter}
                                    showPicker={showPicker}
                                    setShowPicker={setShowPicker}
                                    handleSelect={handleSelect}
                                />
                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                <div style={{ width: "100%", height: 500 }}>

                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart
                                            width={500}
                                            height={300}
                                            data={data}
                                            margin={{
                                                top: 20,
                                                right: 30,
                                                left: 20,
                                                bottom: 5,
                                            }}
                                        >
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="_id" />
                                            <YAxis />
                                            <Tooltip content={<CustomTooltip />} />
                                            <Legend />
                                            <Bar dataKey="count" stackId="a" fill="#8884d8" />
                                            {/* <LabelList dataKey="count" position="center" color="#ffffff"

                                                />
                                            </Bar> */}

                                        </BarChart>
                                    </ResponsiveContainer>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default StatisticsLinesCount;

import { DATE_FORMAT } from "helpers/common";
import React from "react";


const Columns = ({ }) => [
    {
        dataField: "task_id",
        text: "Task Id",
        formatter: (_, row) => <span className="text-capitalize">{row?.taskDetails?.taskID}</span>,
    },

    {
        dataField: "task_name",
        text: "Name",
        formatter: (_, row) => <span className="text-capitalize">{row?.taskDetails?.title}</span>,
    },
    {
        dataField: "type",
        text: "Type",
        formatter: (_, row) => <span className="text">{row?.taskDetails?.type}</span>,
    },
    {
        dataField: "award",
        text: "Award",
        formatter: (_, row) => <div className="text">
            <span className="icon mr-2" style={{ width: 40, height: 40 }}>
                {row?.taskDetails?.awardType === "coins" ?
                    <img src={"/assets/images/btncCoin.png"} width="40px" height="40px" />
                    : <img src={row?.taskDetails?.awardData?.icon} width="40px" height="40px" />
                }
            </span>
            <span>
                {row?.taskDetails?.awardType === "coins" ? row?.taskDetails?.coins : row?.taskDetails?.awardData?.quantity}
            </span>
        </div>,
    },
    {
        dataField: "completedAt",
        text: "Completed At",
        formatter: (_, row) => <span className="text-capitalize">{row?.taskDetails?.date_created_utc && DATE_FORMAT(row?.taskDetails?.date_created_utc)}</span>,
    }
];
export default Columns;

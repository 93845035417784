import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { getCoinsListApi, getSettings, updateGameSettings, uploadFile } from "store/actions";
import { Row, Col, FormGroup, Label, Input, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./Instructions";
import Select from 'react-select';
import { Alert, Button } from "react-bootstrap";
import { checkPermissions } from "helpers/common";


const cryptoList = [
  {
    "name": "Bitcoin",
    "icon": "https://cryptologos.cc/logos/bitcoin-btc-logo.png",
    "symbol": "BTC"
  },
  {
    "name": "Ethereum",
    "icon": "https://cryptologos.cc/logos/ethereum-eth-logo.png",
    "symbol": "ETH"
  },
  {
    "name": "Tether",
    "icon": "https://cryptologos.cc/logos/tether-usdt-logo.png",
    "symbol": "USDT"
  },
  {
    "name": "Binance Coin",
    "icon": "https://cryptologos.cc/logos/binance-coin-bnb-logo.png",
    "symbol": "BNB"
  },
  {
    "name": "USD Coin",
    "icon": "https://cryptologos.cc/logos/usd-coin-usdc-logo.png",
    "symbol": "USDC"
  },
  {
    "name": "Ripple",
    "icon": "https://cryptologos.cc/logos/ripple-xrp-logo.png",
    "symbol": "XRP"
  },
  {
    "name": "Cardano",
    "icon": "https://cryptologos.cc/logos/cardano-ada-logo.png",
    "symbol": "ADA"
  },
  {
    "name": "Dogecoin",
    "icon": "https://cryptologos.cc/logos/dogecoin-doge-logo.png",
    "symbol": "DOGE"
  },
  {
    "name": "Polygon",
    "icon": "https://cryptologos.cc/logos/polygon-matic-logo.png",
    "symbol": "MATIC"
  },
  {
    "name": "Solana",
    "icon": "https://cryptologos.cc/logos/solana-sol-logo.png",
    "symbol": "SOL"
  }
]
const MGameSettings = () => {
  // const  t =useTransition();
  const dispatch = useDispatch();
  const { settings, loading, error } = useSelector(({ Settings }) => Settings);
  const { coinsList, loadingCoins } = useSelector(({ Game }) => Game);

  const [options, setOptions] = useState();
  const { user } = useSelector((state) => state.Auth);
  const [permissions, setPermissions] = useState({
    add: false,
    edit: false,
    delete: false
  })

  useEffect(() => {
    if (user?.permissions) {
      setPermissions({
        add: false,
        edit: checkPermissions(["edit_game_settings"], user?.permissions),
        delete: false
      })
    }
  }, [user]);



  useEffect(() => {
    dispatch(getCoinsListApi({}));
  }, []);


  useEffect(() => {
    let data = [];
    if (coinsList?.length) {
      data = (coinsList).map((item) => ({ label: item.name, value: item.name }));

    }
    //  else {
    //   data = (cryptoList).map((item) => ({ label: item.name, value: item.name }));
    // }
    setOptions(data);
  }, [coinsList]);

  const findDefaultOption = (value) => {
    try {
      return options.find(option => option.value === value);

    } catch {
      return null;
    }
  };

  const [fields, setFields] = useState(
    {
      "game": {
        "minLine1": 1,
        "maxLine1": 10,
        "bombCountLine1": 1,
        "coinTotalLine1": 10,
        "coinValueLine1": 1,
        "coinNameLine1": "",
        "coinSymbolLine1": "",
        "coinIconLine1": "",
        "roundCountLine1": 0,
        "userCountLine1": 0,
        "coinStatusLine1": "disabled",

        "minLine2": 11,
        "maxLine2": 20,
        "bombCountLine2": 1,
        "coinTotalLine2": 10,
        "coinValueLine2": 1,
        "coinNameLine2": "",
        "coinSymbolLine2": "",
        "coinIconLine2": "",
        "roundCountLine2": 0,
        "userCountLine2": 0,
        "coinStatusLine2": "disabled",

        "minLine3": 21,
        "maxLine3": 30,
        "bombCountLine3": 1,
        "coinTotalLine3": 10,
        "coinValueLine3": 1,
        "coinNameLine3": "",
        "coinSymbolLine3": "",
        "coinIconLine3": "",
        "roundCountLine3": 0,
        "userCountLine3": 0,
        "coinStatusLine3": "disabled",

        "minLine4": 31,
        "maxLine4": 40,
        "bombCountLine4": 1,
        "coinTotalLine4": 10,
        "coinValueLine4": 1,
        "coinNameLine4": "",
        "coinSymbolLine4": "",
        "coinIconLine4": "",
        "roundCountLine4": 0,
        "userCountLine4": 0,
        "coinStatusLine4": "disabled",

        "minLine5": 41,
        "maxLine5": 50,
        "bombCountLine5": 1,
        "coinTotalLine5": 10,
        "coinValueLine5": 1,
        "coinNameLine5": "",
        "coinSymbolLine5": "",
        "coinIconLine5": "",
        "roundCountLine5": 0,
        "userCountLine5": 0,
        "coinStatusLine5": "disabled",

        "minLine6": 51,
        "maxLine6": 60,
        "bombCountLine6": 2,
        "coinTotalLine6": 10,
        "coinValueLine6": 1,
        "coinNameLine6": "",
        "coinSymbolLine6": "",
        "coinIconLine6": "",
        "roundCountLine6": 0,
        "userCountLine6": 0,
        "coinStatusLine6": "disabled",

        "minLine7": 61,
        "maxLine7": 70,
        "bombCountLine7": 2,
        "coinTotalLine7": 10,
        "coinValueLine7": 1,
        "coinNameLine7": "",
        "coinSymbolLine7": "",
        "coinIconLine7": "",
        "roundCountLine7": 0,
        "userCountLine7": 0,
        "coinStatusLine7": "disabled",

        "minLine8": 71,
        "maxLine8": 80,
        "bombCountLine8": 2,
        "coinTotalLine8": 10,
        "coinValueLine8": 1,
        "coinNameLine8": "",
        "coinSymbolLine8": "",
        "coinIconLine8": "",
        "roundCountLine8": 0,
        "userCountLine8": 0,
        "coinStatusLine8": "disabled",

        "minLine9": 81,
        "maxLine9": 90,
        "bombCountLine9": 3,
        "coinTotalLine9": 10,
        "coinValueLine9": 1,
        "coinNameLine9": "",
        "coinSymbolLine9": "",
        "coinIconLine9": "",
        "roundCountLine9": 0,
        "userCountLine9": 0,
        "coinStatusLine9": "disabled",

        "minLine10": 91,
        "maxLine10": 100,
        "bombCountLine10": 3,
        "coinTotalLine10": 10,
        "coinValueLine10": 1,
        "coinNameLine10": "",
        "coinSymbolLine10": "",
        "coinIconLine10": "",
        "roundCountLine10": 0,
        "userCountLine10": 0,
        "coinStatusLine10": "disabled",

        "minLine11": 91,
        "maxLine11": 110,
        "bombCountLine11": 3,
        "coinTotalLine11": 11,
        "coinValueLine11": 1,
        "coinNameLine11": "",
        "coinSymbolLine11": "",
        "coinIconLine11": "",
        "roundCountLine11": 0,
        "userCountLine11": 0,
        "coinStatusLine11": "disabled",

        "minLine12": 91,
        "maxLine12": 120,
        "bombCountLine12": 3,
        "coinTotalLine12": 12,
        "coinValueLine12": 1,
        "coinNameLine12": "",
        "coinSymbolLine12": "",
        "coinIconLine12": "",
        "roundCountLine12": 0,
        "userCountLine12": 0,
        "coinStatusLine12": "disabled",

        "minLine13": 91,
        "maxLine13": 130,
        "bombCountLine13": 3,
        "coinTotalLine13": 13,
        "coinValueLine13": 1,
        "coinNameLine13": "",
        "coinSymbolLine13": "",
        "coinIconLine13": "",
        "roundCountLine13": 0,
        "userCountLine13": 0,
        "coinStatusLine13": "disabled",

        "minLine14": 91,
        "maxLine14": 140,
        "bombCountLine14": 3,
        "coinTotalLine14": 14,
        "coinValueLine14": 1,
        "coinNameLine14": "",
        "coinSymbolLine14": "",
        "coinIconLine14": "",
        "roundCountLine14": 0,
        "userCountLine14": 0,
        "coinStatusLine14": "disabled",

        "minLine15": 91,
        "maxLine15": 150,
        "bombCountLine15": 3,
        "coinTotalLine15": 15,
        "coinValueLine15": 1,
        "coinNameLine15": "",
        "coinSymbolLine15": "",
        "coinIconLine15": "",
        "roundCountLine15": 0,
        "userCountLine15": 0,
        "coinStatusLine15": "disabled"
      }

    }
  );


  useEffect(() => {
    dispatch(getSettings());
  }, [])

  useEffect(() => {
    if (settings?._id) {
      console.log("settings", settings)
      setFields((prevState) => ({
        ...prevState,
        game: settings?.game,
        leaderboard: settings?.leaderboard,
        energy: settings?.energy
      }));
    }
  }, [JSON.stringify(settings)]);

  const bombLines = ["bombCountLine1", "bombCountLine2", "bombCountLine3", "bombCountLine4", "bombCountLine5", "bombCountLine6", "bombCountLine7", "bombCountLine8", "bombCountLine9", "bombCountLine10", "bombCountLine11", "bombCountLine12", "bombCountLine13", "bombCountLine14", "bombCountLine15"]
  const handleChange = (key) => (event) => {
    console.log("event", event)
    const { name, value } = event?.target;
    if (bombLines.includes(name) && parseInt(value) > 3) {
      return;
    }
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: Number(value) }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };

  function submit() {
    const findCoinSymbolLine1 = coinsList.find((item) => item.name === fields?.game?.coinNameLine1)?.symbol;
    const findCoinIconLine1 = coinsList.find((item) => item.name === fields?.game?.coinNameLine1)?.icon;
    const findCoinSymbolLine2 = coinsList.find((item) => item.name === fields?.game?.coinNameLine2)?.symbol;
    const findCoinIconLine2 = coinsList.find((item) => item.name === fields?.game?.coinNameLine2)?.icon;
    const findCoinSymbolLine3 = coinsList.find((item) => item.name === fields?.game?.coinNameLine3)?.symbol;
    const findCoinIconLine3 = coinsList.find((item) => item.name === fields?.game?.coinNameLine3)?.icon;
    const findCoinSymbolLine4 = coinsList.find((item) => item.name === fields?.game?.coinNameLine4)?.symbol;
    const findCoinIconLine4 = coinsList.find((item) => item.name === fields?.game?.coinNameLine4)?.icon;
    const findCoinSymbolLine5 = coinsList.find((item) => item.name === fields?.game?.coinNameLine5)?.symbol;
    const findCoinIconLine5 = coinsList.find((item) => item.name === fields?.game?.coinNameLine5)?.icon;
    const findCoinSymbolLine6 = coinsList.find((item) => item.name === fields?.game?.coinNameLine6)?.symbol;
    const findCoinIconLine6 = coinsList.find((item) => item.name === fields?.game?.coinNameLine6)?.icon;
    const findCoinSymbolLine7 = coinsList.find((item) => item.name === fields?.game?.coinNameLine7)?.symbol;
    const findCoinIconLine7 = coinsList.find((item) => item.name === fields?.game?.coinNameLine7)?.icon;
    const findCoinSymbolLine8 = coinsList.find((item) => item.name === fields?.game?.coinNameLine8)?.symbol;
    const findCoinIconLine8 = coinsList.find((item) => item.name === fields?.game?.coinNameLine8)?.icon;
    const findCoinSymbolLine9 = coinsList.find((item) => item.name === fields?.game?.coinNameLine9)?.symbol;
    const findCoinIconLine9 = coinsList.find((item) => item.name === fields?.game?.coinNameLine9)?.icon;
    const findCoinSymbolLine10 = coinsList.find((item) => item.name === fields?.game?.coinNameLine10)?.symbol;
    const findCoinIconLine10 = coinsList.find((item) => item.name === fields?.game?.coinNameLine10)?.icon;

    const findCoinSymbolLine11 = coinsList.find((item) => item.name === fields?.game?.coinNameLine11)?.symbol;
    const findCoinIconLine11 = coinsList.find((item) => item.name === fields?.game?.coinNameLine11)?.icon;

    const findCoinSymbolLine12 = coinsList.find((item) => item.name === fields?.game?.coinNameLine12)?.symbol;
    const findCoinIconLine12 = coinsList.find((item) => item.name === fields?.game?.coinNameLine12)?.icon;


    const findCoinSymbolLine13 = coinsList.find((item) => item.name === fields?.game?.coinNameLine13)?.symbol;
    const findCoinIconLine13 = coinsList.find((item) => item.name === fields?.game?.coinNameLine13)?.icon;

    const findCoinSymbolLine14 = coinsList.find((item) => item.name === fields?.game?.coinNameLine14)?.symbol;
    const findCoinIconLine14 = coinsList.find((item) => item.name === fields?.game?.coinNameLine14)?.icon;

    const findCoinSymbolLine15 = coinsList.find((item) => item.name === fields?.game?.coinNameLine15)?.symbol;
    const findCoinIconLine15 = coinsList.find((item) => item.name === fields?.game?.coinNameLine15)?.icon;

    dispatch(
      updateGameSettings({
        data: {
          ...fields.game,
          coinSymbolLine1: findCoinSymbolLine1,
          coinIconLine1: findCoinIconLine1,
          coinSymbolLine2: findCoinSymbolLine2,
          coinIconLine2: findCoinIconLine2,
          coinSymbolLine3: findCoinSymbolLine3,
          coinIconLine3: findCoinIconLine3,
          coinSymbolLine4: findCoinSymbolLine4,
          coinIconLine4: findCoinIconLine4,
          coinSymbolLine5: findCoinSymbolLine5,
          coinIconLine5: findCoinIconLine5,
          coinSymbolLine6: findCoinSymbolLine6,
          coinIconLine6: findCoinIconLine6,
          coinSymbolLine7: findCoinSymbolLine7,
          coinIconLine7: findCoinIconLine7,
          coinSymbolLine8: findCoinSymbolLine8,
          coinIconLine8: findCoinIconLine8,
          coinSymbolLine9: findCoinSymbolLine9,
          coinIconLine9: findCoinIconLine9,
          coinSymbolLine10: findCoinSymbolLine10,
          coinIconLine10: findCoinIconLine10,
          coinSymbolLine11: findCoinSymbolLine11,
          coinIconLine11: findCoinIconLine11,
          coinSymbolLine12: findCoinSymbolLine12,
          coinIconLine12: findCoinIconLine12,
          coinSymbolLine13: findCoinSymbolLine13,
          coinIconLine13: findCoinIconLine13,
          coinSymbolLine14: findCoinSymbolLine14,
          coinIconLine14: findCoinIconLine14,
          coinSymbolLine15: findCoinSymbolLine15,
          coinIconLine15: findCoinIconLine15,
        }
      }));
  }


  const handleChangeString = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };
    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: value }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };


  const handleSelectChange = (key) => (event) => {
    console.log("event", event.target.value)
    const { name, value } = event.target;
    let copySettings = { ...fields };

    copySettings = {
      ...copySettings,
      [key]: { ...copySettings[key], [name]: value }
    }
    console.log("copySettingscopySettings", copySettings)
    setFields(copySettings);
  };
  // const handleUploadImage = (name) => (file) => {
  //   console.log("file", file, "name", name)
  //   if (!file) return;
  //   dispatch(uploadFile({
  //     data: file, callback: (err, response) => {
  //       if (err) return;
  //       if (response?.status === "success") {
  //         console.log("datadata", response)
  //         const url = response?.data?.url;
  //         setFields((pre) => ({ ...pre, game: { ...pre.game, [name]: url } }))
  //       }
  //     }
  //   }))
  // }

  // const handleChangeRemoveFile = (name) => {
  //   setFields((pre) => ({ ...pre, game: { ...pre.game, [name]: null } }))
  // };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col sm={12} lg={12}>
              <Card>
                <CardBody className="spinner-content">
                  {error && typeof error === "string" ? (
                    <Alert color="danger">{error}</Alert>
                  ) : null}

                  {loading && <div className="spinner"></div>}

                  <Col lg={8}>
                    <Instructions />
                  </Col>

                  <Col lg={8}>
                    <Row>

                      {/* <Col md={6} /> */}
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine1}
                            name="minLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />



                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine1}
                            name="maxLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine1}
                            name="bombCountLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine1}
                            name="coinTotalLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine1}
                            name="coinValueLine1"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine1)}
                            name="coinNameLine1"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine1", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine1"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 1 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine1}
                          name="roundCountLine1"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 1 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine1}
                          name="userCountLine1"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 1 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine1 === "enabled" ? true : false}
                            id="coinStatusLine1" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine1 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine1", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine1" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Range</Label>
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine2}
                            name="minLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine2}
                            name="maxLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine2}
                            name="bombCountLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine2}
                            name="coinTotalLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine2}
                            name="coinValueLine2"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine2)}
                            name="coinNameLine2"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine2", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine2"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 2 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine2}
                          name="roundCountLine2"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 2 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine2}
                          name="userCountLine2"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 2 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine2 === "enabled" ? true : false}
                            id="coinStatusLine2" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine2 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine2", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine2" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine3}
                            name="minLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine3}
                            name="maxLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine3}
                            maxValue={fields?.minigameSettings?.maxLine3}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine3", "maxLine3")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine3}
                            name="bombCountLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine3}
                            name="coinTotalLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine3}
                            name="coinValueLine3"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine3)}
                            name="coinNameLine3"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine3", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine3"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 3 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine3}
                          name="roundCountLine3"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 3 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine3}
                          name="userCountLine3"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 3 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine3 === "enabled" ? true : false}
                            id="coinStatusLine3" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine3 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine3", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine3" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine4}
                            name="minLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine4}
                            name="maxLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine4}
                            maxValue={fields?.minigameSettings?.maxLine4}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine4", "maxLine4")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine4}
                            name="bombCountLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine4}
                            name="coinTotalLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine4}
                            name="coinValueLine4"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine4)}
                            name="coinNameLine4"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine4", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine4"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 4 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine4}
                          name="roundCountLine4"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 4 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine4}
                          name="userCountLine4"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 4 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine4 === "enabled" ? true : false}
                            id="coinStatusLine4" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine4 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine4", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine4" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Range</Label>


                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine5"
                            value={fields?.game?.minLine5}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            name="maxLine5"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine5}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine5}
                            name="bombCountLine5"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine5}
                            name="coinTotalLine5"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine5}
                            name="coinValueLine5"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine5)}
                            name="coinNameLine5"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine5", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine5"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 5 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine5}
                          name="roundCountLine5"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 5 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine5}
                          name="userCountLine5"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 5 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine5 === "enabled" ? true : false}
                            id="coinStatusLine5" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine5 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine5", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine5" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine6}
                            name="minLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine6}
                            name="maxLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine6}
                            name="bombCountLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine6}
                            name="coinTotalLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine6}
                            name="coinValueLine6"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine6)}
                            name="coinNameLine6"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine6", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine6"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 6 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine6}
                          name="roundCountLine6"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 6 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine6}
                          name="userCountLine6"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 6 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine6 === "enabled" ? true : false}
                            id="coinStatusLine6" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine6 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine6", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine6" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine7}
                            name="minLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            name="maxLine7"
                            value={fields?.game?.maxLine7}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />


                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine7}
                            name="bombCountLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine7}
                            name="coinTotalLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine7}
                            name="coinValueLine7"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine7)}
                            name="coinNameLine7"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine7", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine7"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 7 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine7}
                          name="roundCountLine7"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 7 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine7}
                          name="userCountLine7"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 7 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine7 === "enabled" ? true : false}
                            id="coinStatusLine7" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine7 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine7", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine7" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>



                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine8}
                            name="minLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine8}
                            name="maxLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />
                          {/* <MultiRangeSlider
                          className="m-2 multi-range-slider-black"
                            min={1}
                            max={1000}
                            step={1}
                            minValue={fields?.minigameSettings?.minLine8}
                            maxValue={fields?.minigameSettings?.maxLine8}
                            ruler= {false}
                            onChange={(e) => handleSliderChange("minLine8", "maxLine8")(e)}
                          /> */}
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine8}
                            name="bombCountLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine8}
                            name="coinTotalLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine8}
                            name="coinValueLine8"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine8)}
                            name="coinNameLine8"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine8", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine8"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 8 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine8}
                          name="roundCountLine8"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 8 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine8}
                          name="userCountLine8"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 8 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine8 === "enabled" ? true : false}
                            id="coinStatusLine8" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine8 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine8", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine8" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Range</Label>
                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            value={fields?.game?.minLine9}
                            name="minLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine9}
                            name="maxLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />


                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine9}
                            name="bombCountLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>


                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine9}
                            name="coinTotalLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine9}
                            name="coinValueLine9"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine9)}
                            name="coinNameLine9"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine9", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine9"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 9 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine9}
                          name="roundCountLine9"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 9 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine9}
                          name="userCountLine9"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 9 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine9 === "enabled" ? true : false}
                            id="coinStatusLine9" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine9 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine9", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine9" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine10"
                            value={fields?.game?.minLine10}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine10}
                            name="maxLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine10}
                            name="bombCountLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine10}
                            name="coinTotalLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine10}
                            name="coinValueLine10"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine10)}
                            name="coinNameLine10"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine10", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine10"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 10 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine10}
                          name="roundCountLine10"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 10 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine10}
                          name="userCountLine10"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 10 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine10 === "enabled" ? true : false}
                            id="coinStatusLine10" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine10 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine10", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine10" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 11 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine11"
                            value={fields?.game?.minLine11}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine11}
                            name="maxLine11"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 11 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine11}
                            name="bombCountLine11"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 11 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine11}
                            name="coinTotalLine11"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 11 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine11}
                            name="coinValueLine11"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 11 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine11)}
                            name="coinNameLine11"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine11", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine11"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 11 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine11}
                          name="roundCountLine11"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 11 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine11}
                          name="userCountLine11"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 11 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine11 === "enabled" ? true : false}
                            id="coinStatusLine11" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine11 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine11", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine11" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 12 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine12"
                            value={fields?.game?.minLine12}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine12}
                            name="maxLine12"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 12 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine12}
                            name="bombCountLine12"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 12 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine12}
                            name="coinTotalLine12"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 12 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine12}
                            name="coinValueLine12"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 12 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine12)}
                            name="coinNameLine12"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine12", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine12"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 12 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine12}
                          name="roundCountLine12"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 12 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine12}
                          name="userCountLine12"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 12 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine12 === "enabled" ? true : false}
                            id="coinStatusLine12" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine12 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine12", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine12" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 13 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine13"
                            value={fields?.game?.minLine13}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine13}
                            name="maxLine13"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 13 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine13}
                            name="bombCountLine13"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 13 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine13}
                            name="coinTotalLine13"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 13 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine13}
                            name="coinValueLine13"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 13 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine13)}
                            name="coinNameLine13"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine13", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine13"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 13 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine13}
                          name="roundCountLine13"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 13 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine13}
                          name="userCountLine13"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 13 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine13 === "enabled" ? true : false}
                            id="coinStatusLine13" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine13 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine13", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine13" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 14 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine14"
                            value={fields?.game?.minLine14}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine14}
                            name="maxLine14"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 14 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine14}
                            name="bombCountLine14"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 14 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine14}
                            name="coinTotalLine14"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 14 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine14}
                            name="coinValueLine14"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 14 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine14)}
                            name="coinNameLine14"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine14", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine14"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 14 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine14}
                          name="roundCountLine14"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 14 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine14}
                          name="userCountLine14"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 14 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine14 === "enabled" ? true : false}
                            id="coinStatusLine14" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine14 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine14", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine14" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 15 Coin Range</Label>

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Min Value"
                            name="minLine15"
                            value={fields?.game?.minLine15}
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                          <Input
                            className="m-2"
                            type="number"
                            placeholder="Max Value"
                            value={fields?.game?.maxLine15}
                            name="maxLine15"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            step={1}
                          />

                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 15 Bomb Count</Label>

                          <Input
                            type="number"
                            value={fields?.game?.bombCountLine15}
                            name="bombCountLine15"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 15 Coin Total</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinTotalLine15}
                            name="coinTotalLine15"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 15 Coin Value</Label>

                          <Input
                            type="number"
                            value={fields?.game?.coinValueLine15}
                            name="coinValueLine15"
                            onChange={
                              (e) => handleChange("game")(e)
                            }
                            min={1}
                            max={3}
                            step={1}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 15 Coin Name</Label>
                          <Select
                            className="mt-3"
                            value={findDefaultOption(fields?.game?.coinNameLine15)}
                            name="coinNameLine15"
                            onChange={
                              (e) => handleSelectChange("game")({ target: { name: "coinNameLine15", value: e.value } })
                            }
                            options={options}
                            isSearchable={true} // Enable search/filter functionality
                            placeholder="Select..."
                            id="coinNameLine15"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <Label>Line 15 Round Count</Label>

                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.roundCountLine15}
                          name="roundCountLine15"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />

                      </Col>
                      <Col md={6}>
                        <Label>Line 15 User Count</Label>
                        <Input
                          className="m-2"
                          type="number"
                          placeholder=""
                          value={fields?.game?.userCountLine15}
                          name="userCountLine15"
                          onChange={
                            (e) => handleChange("game")(e)
                          }
                          min={1}
                          step={1}
                        />


                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Label>Line 15 Coin Status</Label>
                          <input type="checkbox"
                            checked={fields?.game?.coinStatusLine15 === "enabled" ? true : false}
                            id="coinStatusLine15" className="form-check" onClick={() => {
                              const value =
                                fields?.game?.coinStatusLine15 === "enabled" ? "disabled" : "enabled";
                              console.log("value", value);

                              handleChangeString("game")({ target: { name: "coinStatusLine15", value: value } });
                            }}
                          />
                          <label htmlFor="coinStatusLine15" className="form-lable m-0">Enabled</label>
                        </FormGroup>
                      </Col>

                      <Col md={6} />
                      {permissions.edit ?
                        <Col className="d-flex justify-content-end">
                          {loading && <Spinner color="primary" className="mr-2" />}

                          <Button className="btn btn-primary" onClick={submit}>
                            Save Changes
                          </Button>
                        </Col>
                        : null}
                    </Row>
                  </Col>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};

export default MGameSettings;
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { USER } from "common/viewContent";
import * as Path from "routes/Path/index";
import { gameEndApi, gameHistoryDetailsApi } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import moment from "moment";
import { decrypt } from "helpers/secretManager";
import { DATE_FORMAT } from "helpers/common";

const GameHistoryView = () => {
    const { id } = useParams();
    const { loading } = useSelector(({ Game }) => Game)
    const { loadingEndGame } = useSelector(({ Users }) => Users);
    const [data, setData] = useState(null);


    const dispatch = useDispatch();

    useEffect(() => {
        console.log("id", id)
        if (id) {
            dispatch(gameHistoryDetailsApi({
                data: { _id: id }, callBack: async (err, response) => {
                    const res = response?.data;

                    if (res?._id) {
                        try {
                            console.log("inside try", res)
                            let updatedData = { ...res, gameData: [] }

                            let decoded = res?.gameData ? await decrypt(res?.gameData) : [];
                            decoded = decoded ? JSON.parse(decoded) : []
                            updatedData = {
                                ...updatedData,
                                gameData: decoded?.reverse()
                            }
                            setData(updatedData);
                        } catch (err) {
                            console.log("error inside try useEffetc", err)
                        }
                    }
                }
            }));
        }
    }, [id]);

    const status = {
        "active": "Active",
        "completed": "Completed",
        "ended": "Ended"
    }

    const onEndGame = () => {
        if (!data?._id) return;
        dispatch(gameEndApi({
            data: { _id: data?._id }, callBack: (err, res) => {
                if (err) return;
                window.location.reload();
            }
        }))

    }


    return (
        <>
            <section className="section">
                <div className="section-header">
                    <h1>Game Details</h1>

                    <div className="section-header-breadcrumb">
                        <div className="breadcrumb-item active">
                            <Link to={"/admin/" + Path.gameHisory}>Game History</Link>
                        </div>
                        <div className="breadcrumb-item">Game History Detail</div>
                    </div>
                </div>
                <div className="section-body">
                    <div className="card">

                        <Row>
                            <Col lg="12" className="my-2 px-4">
                                <h4 className="text-primary">Match Details</h4>
                                <ul className="list-unstyled ps-0 mb-0 mt-3 row">
                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className=" ">
                                            RoundId :{" "}
                                            <span className="font-weight-bold">
                                                {data?.roundId || "--"}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Telegram Username :{" "}
                                            <span className="font-weight-bold">
                                                {data?.userDetails?.telegram_username || "--"}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Email :{" "}
                                            <span className="font-weight-bold">
                                                {data?.userDetails?.telegramId || "--"}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Line Count :{" "}
                                            <span className="font-weight-bold">
                                                {data?.lineCount || 0}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Previous Energy Balance :{" "}
                                            <span className="font-weight-bold">
                                                {data?.prevEnergyBalance || 0}
                                            </span>
                                        </p>
                                    </li>
                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            BTNC Earned :{
                                                " "}
                                            <span className="font-weight-bold">
                                                {data?.winnings?.find((item) => item?.symbol === "BTNC")?.amount || 0}
                                            </span>
                                        </p>
                                    </li>

                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Status :{" "}
                                            <span className="font-weight-bold">
                                                {data?.status ? status[data?.status] : "--"}
                                            </span>
                                        </p>
                                    </li>
                                    {data?.status === "active" ?
                                        <li className="col-lg-4 col-sm-6 my-2">
                                            <Button onClick={() => onEndGame()}
                                                className="btn">
                                                {loadingEndGame && <Spinner size="sm" className="mr-2" />}End Game</Button>
                                        </li>
                                        : null}

                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Started At :{" "}
                                            <span className="font-weight-bold">
                                                {data?.date_created_utc ?
                                                    DATE_FORMAT(data?.date_created_utc) :
                                                    "--"}
                                            </span>
                                        </p>
                                    </li>

                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Completed At :{" "}
                                            <span className="font-weight-bold">
                                                {data?.completedAt ?
                                                    DATE_FORMAT(data?.completedAt) :
                                                    "--"}
                                            </span>
                                        </p>
                                    </li>

                                    <li className="col-lg-4 col-sm-6 my-2">
                                        <p className="">
                                            Ended At :{" "}
                                            <span className="font-weight-bold">
                                                {data?.endedAt ?
                                                    DATE_FORMAT(data?.endedAt) :
                                                    "--"}
                                            </span>
                                        </p>
                                    </li>

                                </ul>
                            </Col>
                            <Col lg="12" className="my-2">
                                <h4 className="text-primary">Game Details</h4>
                                <div className="array-container">
                                    {data?.gameData?.map((item, index) => (
                                        <div key={index} className="array-row">
                                            <p className="mt-3 mr-3" style={{ width: 20 }}>{data?.gameData?.length - index}</p>
                                            {item.row.map((cell, cellIndex) => (
                                                <p key={cellIndex} className={`array-cell background-${cell.isOpened} ${cell.valueType}`}>
                                                    {cell.valueType === "bomb" && <img className={"leaderboard-icon"} src={cell?.coinIcon} />}
                                                    {cell.valueType === "coin" && (<>
                                                        <span className="me-1">{cell.value}</span>
                                                        <img className={"leaderboard-icon"} style={{ height: 28 }} src={cell?.coinIcon} />
                                                    </>)}
                                                    {cell.valueType === "BTNC" && <>
                                                        <span className="me-1">{cell.value}</span> <img className={"leaderboard-icon"} style={{ height: 28 }} src={cell?.coinIcon} /></>}
                                                </p>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </>
    );
};

export default GameHistoryView;

import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//imported
import { PROFILE } from "common/viewContent";
import * as Path from "routes/Path/index";

import FormikForm from "components/shared/FormikForm";
import Validation from "./Validation";
import InputFields from "./inputFields";

import { updateAdmin, viewUser } from "store/actions";
import { toast } from "react-toastify";

const Profile = ({ submitting, loader }) => {
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const initialValues = {
    _id: user ? user._id : "",
    name: user ? user.name : "",
    email: user ? user.email : "",
  };

  //get data
  // async function getData() {
  //   try {
  //     dispatch(viewUser());
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }
  // useEffect(() => {
  //   getData();
  // }, []);

  // update profile
  const updateProfileSubmit_ = async (values) => {

    try {
      let query = {
        _id: values._id,
        name: values.name,
        email: values.email,
      };

      const callBack = (err, res) => {
        if (err) {
          toast.error(err);
        }
        else {
          // getData();
          toast.success("Profile Updated");
        }
      };
      dispatch(updateAdmin({ data: query, callBack }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Helmet title={PROFILE.CURRENT_MODULE} />
      <section className="section">
        <div className="section-header">
          <h1>{PROFILE.CURRENT_MODULE}</h1>
          {/* <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">Profile</div>
          </div> */}
        </div>
        <div className="section-body">
          <h2 className="section-title">Hi, {user?.name}!</h2>
          <p className="section-lead m-2">
            Change information about yourself on this page.
          </p>

          <div className="row mt-sm-4">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <FormikForm
                    initialValues={initialValues}
                    validationSchema={Validation}
                    onSubmit={updateProfileSubmit_}
                    loading={loader}
                    inputFields={InputFields}
                    submitting={submitting}
                    buttonText="Submit"
                    is_block={false}
                    col={6}
                    imageRemoveButton={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* } */}
      </section>
    </>
  );
};

export default Profile;

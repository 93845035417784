import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Actions
import { getSettings, updateMaintenanceSettings } from "store/actions";
import { Row, Col, FormGroup, Label, Input, Spinner, Container, Card, CardBody } from "reactstrap";
import Instructions from "./instructions";
import Select from 'react-select';
import { Alert, Button } from "react-bootstrap";
import { checkPermissions } from "helpers/common";


const MaintenanceSettings = () => {
    // const  t =useTransition();
    const dispatch = useDispatch();
    const { settings, loading, error } = useSelector(({ Settings }) => Settings);


    const [fields, setFields] = useState(
        {
            maintenance: {
                status: false,
                alertEmails: []
            }
        }
    );
    const { user } = useSelector((state) => state.Auth);
    const [permissions, setPermissions] = useState({
        add: false,
        edit: false,
        delete: false
    })

    useEffect(() => {
        if (user?.permissions) {
            setPermissions({
                add: false,
                edit: checkPermissions(["edit_maintenance_settings"], user?.permissions),
                delete: false
            })
        }
    }, [user]);


    useEffect(() => {
        dispatch(getSettings());
    }, [])

    useEffect(() => {
        if (settings?._id) {
            console.log("settings", settings)
            setFields((prevState) => ({
                ...prevState,
                game: settings?.game,
                leaderboard: settings?.leaderboard,
                energy: settings?.energy,
                maintenance: settings?.maintenance
            }));
        }
    }, [JSON.stringify(settings)]);

    const handleChangeString = (key) => (event) => {
        console.log("event", event.target.value)
        const { name, value } = event.target;
        let copySettings = { ...fields };
        copySettings = {
            ...copySettings,
            [key]: { ...copySettings[key], [name]: value }
        }
        console.log("copySettingscopySettings", copySettings)
        setFields(copySettings);
    };

    function submit() {
        dispatch(
            updateMaintenanceSettings({
                data: {
                    status: fields?.maintenance?.status,
                    alertEmails: fields.maintenance.alertEmails
                }
            }));
    }

    const onHandleAddEmail = () => {
        setFields((pre) => ({ ...pre, maintenance: { ...pre.maintenance, alertEmails: [...pre.maintenance.alertEmails, ""] } }))

    }

    const onHandleRemoveEmail = (index) => {
        let emails = [...fields.maintenance.alertEmails]
        emails.splice(index, 1);
        setFields((pre) => ({ ...pre, maintenance: { ...pre.maintenance, alertEmails: emails } }))
    }

    const handleChangeEmail = (index, value) => {
        let emails = [...fields.maintenance.alertEmails]
        emails.splice(index, 1, value);
        setFields((pre) => ({ ...pre, maintenance: { ...pre.maintenance, alertEmails: emails } }))

    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col sm={12} lg={12}>
                            <Card>
                                <CardBody className="spinner-content">
                                    {error && typeof error === "string" ? (
                                        <Alert color="danger">{error}</Alert>
                                    ) : null}

                                    {loading && <div className="spinner"></div>}

                                    <Col lg={8}>
                                        <Instructions />
                                    </Col>

                                    <Col lg={8}>
                                        <Row>

                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Status </Label>
                                                    <div className="d-flex align-items-center">
                                                        <input type="checkbox"
                                                            disabled={!permissions.edit}
                                                            checked={fields?.maintenance?.status === false ? false : true}
                                                            id="status" className="form-check" onClick={() => {
                                                                const value = fields?.maintenance?.status === false ? true : false;
                                                                console.log("value ====>", value);

                                                                handleChangeString("maintenance")({ target: { name: "status", value: value } });
                                                            }}
                                                        />
                                                        <label htmlFor="status" className="form-lable pl-2"> Under Maintenance</label>
                                                    </div>

                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Label>Alert Emails </Label>

                                                    {fields?.maintenance.alertEmails?.map((email, index) => {
                                                        return (
                                                            <div className="d-flex m-2">
                                                                <input
                                                                    placeholder="Enter email..."
                                                                    value={email}
                                                                    onChange={(e) => handleChangeEmail(index, e.target.value)}
                                                                    disabled={!permissions.edit}
                                                                    className="mr-2"
                                                                    style={{ width: 200, height: 35 }} />
                                                                {permissions.edit ?
                                                                    <Button onClick={() => onHandleRemoveEmail(index)}>Remove</Button> : null}
                                                            </div>
                                                        )
                                                    })}


                                                </FormGroup>
                                            </Col>
                                            {permissions.edit ?
                                                <Col md={12}>
                                                    <Button onClick={() => onHandleAddEmail()}>Add</Button>
                                                </Col>
                                                : null}
                                            <Col md={12} />
                                            {permissions.edit ?
                                                <Col className="d-flex justify-content-end">
                                                    {loading && <Spinner color="primary" className="mr-2" />}

                                                    <Button className="btn btn-primary" onClick={submit}>
                                                        Save Changes
                                                    </Button>
                                                </Col>
                                                : null}
                                        </Row>
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </Container >
            </div >

        </React.Fragment >
    );
};

export default MaintenanceSettings;
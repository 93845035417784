import React, { useEffect, useRef } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { FieldArray, Formik } from 'formik';
import { useDispatch } from "react-redux";
import { addRoadmapApi, editRoadmapApi } from "store/actions";

export default function AURoadmapsModal({
  show,
  handleClose,
  rowData
}) {
  const refForm = useRef();

  useEffect(() => {

    if (rowData?._id && refForm.current) {
      refForm.current.setValues({
        quarter: rowData?.quarter,
        title: rowData?.title,
        subPoints: rowData?.subPoints || []
      })
    }
  }, [rowData]);

  const dispatch = useDispatch();
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered
      size="lg"
    >
      <Modal.Header className="py-3 text-dark" closeButton>
        <Modal.Title className="fw-bold text-dark">{rowData ? "Update" : "Add"} Roadmap</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <Formik
            innerRef={refForm}
            initialValues={{
              quarter: "",
              title: "",
              subPoints: [{
                status: "pending", // Default value for status
                point: ""
              }]
            }}
            validate={values => {
              const errors = {};
              if (!values.quarter) {
                errors.quarter = 'Quarter is required!';
              }
              if (!values.title) {
                errors.title = 'Title is required!';
              }
              // if (values.subPoints) {
              //   errors.subPoints = values.subPoints.map((subPoint, index) => {
              //     const subPointErrors = {};
              //     if (!subPoint.status) {
              //       subPointErrors.status = 'Status is required';
              //     }
              //     if (!subPoint.point) {
              //       subPointErrors.point = 'Point is required';
              //     }
              //     return subPointErrors;
              //   });
              // }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              console.log("onSubmit call", values)
              if (rowData?._id) {
                dispatch(editRoadmapApi({
                  data: { ...values, _id: rowData?._id }, callback: (err, res) => {
                    if (err) return;
                    handleClose();
                  }
                }));
              } else {
                dispatch(addRoadmapApi({
                  data: values, callback: (err, res) => {
                    if (err) return;
                    handleClose();
                  }
                }));
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit}>
                {console.log("values", values)}
                <div className="form-group">
                  <div className="row">
                    <div className="col-6">
                      <input
                        className={`form-control ${errors.quarter && touched.quarter ? "is-invalid" : ""}`}
                        type="text"
                        name="quarter"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.quarter}
                        placeholder="Enter quarter..."
                      />
                      {errors.quarter && touched.quarter && <div className="invalid-feedback">{errors.quarter}</div>}
                    </div>
                    <div className="col-6">
                      <input
                        className={`form-control ${errors.title && touched.title ? "is-invalid" : ""}`}
                        type="text"
                        name="title"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        placeholder="Enter title..."
                      />
                      {errors.title && touched.title && <div className="invalid-feedback">{errors.title}</div>}
                    </div>
                  </div>

                  <FieldArray
                    name="subPoints"
                    render={arrayHelpers => (
                      <div>
                        <div className="my-4">
                          <h4 className="my-2">Sub Points</h4>
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={() => arrayHelpers.push({ status: "pending", point: "" })}
                          >
                            Add
                          </button>
                        </div>
                        {values?.subPoints?.length &&
                          values.subPoints.map((item, index) => (
                            <div className="row mt-2" key={index}>
                              <div className="col-4">
                                <select
                                  className={`form-control ${errors?.subPoints?.[index]?.status && touched?.subPoints?.[index]?.status ? "is-invalid" : ""
                                    }`}
                                  name={`subPoints.${index}.status`} // Correct dynamic name
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={item.status}
                                >
                                  <option value="pending">Pending</option>
                                  <option value="completed">Completed</option>
                                </select>
                                {errors?.subPoints?.[index]?.status &&
                                  touched?.subPoints?.[index]?.status && (
                                    <div className="invalid-feedback">{errors?.subPoints?.[index]?.status}</div>
                                  )}
                              </div>
                              <div className="col-4">
                                <input
                                  className={`form-control ${errors?.subPoints?.[index]?.point && touched?.subPoints?.[index]?.point ? "is-invalid" : ""
                                    }`}
                                  type="text"
                                  name={`subPoints.${index}.point`} // Correct dynamic name
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={item.point}
                                  placeholder="Enter point..."
                                />
                                {errors?.subPoints?.[index]?.point &&
                                  touched?.subPoints?.[index]?.point && (
                                    <div className="invalid-feedback">{errors?.subPoints?.[index]?.point}</div>
                                  )}
                              </div>
                              <div className="col-4">
                                <button
                                  disabled={values?.subPoints?.length === 1}
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  Remove
                                </button>
                              </div>
                            </div>
                          ))}
                      </div>
                    )}
                  />

                  <div className="col-12 text-end">
                    <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>

        </div>
      </Modal.Body>
    </Modal >
  );
}
import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FadeLoader from "react-spinners/FadeLoader";
import { USER } from "common/viewContent";
import * as Path from "routes/Path/index";
import {
  getUsers,
  updateUser,
  AddUser,
  updateUserStatus,
  exportUsersList,
  deleteUserApi,
} from "store/actions";
import { toast } from "react-toastify";
import Table from "components/Table";
import Columns from "./Columns";
import AddUserModal from "components/Modals/AddUser";
import FormikForm from "components/shared/FormikForm";
import DateRangeFilter from "components/DateRangePicker/DateRangePicker";
import moment from "moment";
import slice from "store/user/slice";
import AddSubAmin from "components/Modals/AddUpdateSubAmin";

const InputFieldsForSearch = [
  {
    name: "search",
    type: "text",
    label: "",
    placeholder: "Search using  name, email...",
  },
  {
    name: "status",
    type: "select",
    label: "",
    placeholder: "Select status",
    options: [
      { value: "active", label: "Active" },
      { value: "blocked", label: "Blocked" },
    ],
  },
];
const initialValuesForFilter = {
  search: "",
  status: "",
};

const List = () => {
  const dispatch = useDispatch();
  const [showAdd, setShowAdd] = useState(false);
  const { users, loading, totalCount } = useSelector((state) => state.Users);
  const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

  const [showPicker, setShowPicker] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 20,
    order: -1,
    orderBy: "createdAt",
    search: "",
    status: "",
    startDate: null,
    endDate: null,
    role: "SUBADMIN"
  });
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    return (() => dispatch(slice.actions.clearUsersList()));
  }, [])

  useEffect(() => {
    getApiData(filter);
    setcolumns(Columns({ handleUserStatus, handleEditHandler, deleteHandler }));
  }, [filter]);

  const deleteHandler = (id) => {
    try {
      const userConfirmed = window.confirm("Are you sure, you want to delete this sub admin?");
      if (userConfirmed) {
        const callback = (err, res) => {
          if (err) {
          } else {
            getApiData(filter);
          }
        };

        dispatch(
          deleteUserApi({
            data: { _id: id },
            callback,
          })
        );
      }
    } catch (error) {
      console.log(error, "<===err");
    }
  };
  async function getApiData(query) {
    try {
      dispatch(getUsers({ data: query }));
    } catch (error) { }
  }

  const handleUserStatus = (id, status) => {
    try {
      const callback = (err, res) => {
        if (err) return;

        getApiData(filter);
      };

      dispatch(
        updateUserStatus({
          data: { _id: id, status: status },
          callback,
        })
      );
    } catch (error) {
      console.log(error, "<===err");
    }
  };


  const handleFilterForm = (info) => {
    setFilter({
      ...filter,
      page: 1,
      limit: 20,
      order: -1,
      orderBy: "date_created_utc",
      search: info.search,
      status: info.status,
    });
  };
  const navigate = useNavigate();
  const handleEditHandler = (info) => {
    setRowData(info);
    setShowAdd(true);
    // navigate(`/admin/subadmin/view/${info?._id}`);
  };
  const handleSelect = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(endDate).format("YYYY-MM-DD");
    setFilter((prevFilter) => ({
      ...prevFilter,
      startDate: formattedStartDate,
      endDate: formattedEndDate,
    }));
    setShowPicker(false);
  };

  return (
    <>
      {loading && (
        <div className="main-loader">
          <FadeLoader size={1000} />
        </div>
      )}
      {showAdd &&
        <AddSubAmin
          rowData={rowData}
          show={true}
          handleClose={() => {
            setShowAdd(false)
            setRowData(null)
          }}
          handleConfirm={() => {
            getApiData(filter);
            setRowData(null)
          }}
        />
      }
      <Helmet title={"Sub Admins"} />

      <section className="section">
        <div className="section-header">
          <h1>Sub Admins</h1>

          {/* <div className="section-header-breadcrumb">
            <div className="breadcrumb-item active">
              <Link to={"/admin/" + Path.dashboard}>Dashboard</Link>
            </div>
            <div className="breadcrumb-item">{USER.CURRENT_MODULE} </div>
          </div> */}
        </div>
        <div className="section-body">
          <div className="card">
            <div className="row justify-content-between">
              <div className="col-6">
                <div className="card-header">
                  <h2 className="section-title">Sub Admins</h2>
                </div>
              </div>
              <div className="col-6">
                <div className="card-header d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-primary"
                    onClick={() => setShowAdd(true)}
                  >
                    Add
                  </button>
                </div>
              </div>
              <div className="col-12">
                <div className="card-header">
                  <FormikForm
                    initialValues={initialValuesForFilter}
                    onSubmit={handleFilterForm}
                    loading={loading}
                    inputFields={InputFieldsForSearch}
                    buttonText={"Search"}
                    dateRange={filter}
                    showPicker={showPicker}
                    setShowPicker={setShowPicker}
                    handleSelect={handleSelect}
                  />
                </div>
              </div>
            </div>
          </div>
          {users?.length > 0 && (
            <Table
              data={users}
              columns={columns}
              setFilter={setFilter}
              total={totalCount}
              filter={filter}
              toggleConfirmModal={handleUserStatus}
            />
          )}
          {!loading && users?.user?.length === 0 && (
            <p style={{ textAlign: "center" }}>No Sub Admin Found</p>
          )}
        </div>
      </section>
    </>
  );
};

export default List;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { post, get, put, updateCSRFToken } from "helpers/api_helper";
import slice from "./slice";
import * as URL from "helpers/url_helper";
import { toast } from "react-toastify";
import { getValuesSocketData } from "helpers/secretManager";

export const loginUser = createAsyncThunk(
  "admin/login",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await post(URL.LOGIN_USER, data, { signature, nonce });
      console.log("response login api hit", response)
      if (response?.status === "failure") {
        toast.error(response?.message);
        return Thunk.rejectWithValue(response?.message);
      }
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(error?.message || "Something went wrong!");
      callBack && callBack(error?.message || "Something went wrong!");

      return Thunk.rejectWithValue(error);
    }
  }
);

export const viewUser = createAsyncThunk(
  "admin/view",
  async ({ callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await get(URL.VIEW_USER, { signature, nonce });
      if (response?.status === "failed") {
        callBack && callBack(response?.message, "");
      }
      callBack && callBack("", response);
      let payload = { ...response.data };
      if (window.location.hostname === "localhost") {
        payload = {
          _id: "6745c2f5639f63fa9a46caca",
          name: "Admin",
          email: "admin@suffescom.com",
        };
      }
      console.log(payload, "payloadaskdhasi");

      return payload;
    } catch (error) {
      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/edit/",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await put(URL.UPDATE_ADMIN + data?._id, data, { signature, nonce });
      if (response?.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, null);
      }
      callBack && callBack(null, response);
      toast.success("Data updated successfully!");
      return response.data;
    } catch (error) {
      toast.error(error?.message);
      callBack(error.message);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const resetPass = createAsyncThunk(
  "admin/reset",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await put(URL.RESET_PASSWORD + data?._id, data, { signature, nonce });
      callBack(null, response);
      return response.data;
    } catch (error) {
      callBack(error.message);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const uploadFile = createAsyncThunk(
  "uploadFile",
  async ({ data, callback }, Thunk) => {
    try {
      const formData = new FormData();
      formData.append("file", data);

      const { signature, nonce } = await getValuesSocketData();
      const config = {
        headers: { "content-type": "multipart/form-data" },
        signature,
        nonce
      };
      let response = await post(URL.POST_FILE, formData, config);
      callback && callback(null, response);
      return response.data;
    } catch (error) {
      console.log("error", error);
      callback(error.message);
      return Thunk.rejectWithValue(error);
    }
  }
);

export const getCsrfToken = createAsyncThunk(
  "getCsrfToken",
  async ({ callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await get(URL.GET_CSRF_TOKEN, { signature, nonce });
      if (response.status === "failure") {
        toast.error(response?.message);
        return Thunk.rejectWithValue(response?.message);
      }
      updateCSRFToken(response.data.token);
      callBack && callBack(null, response);
      return response.data;
    } catch (error) {
      console.log(error, "<===error");
      toast.error(error?.message || "Something went wrong!");
      callBack && callBack(error?.message || "Something went wrong!");

      return Thunk.rejectWithValue(error);
    }
  }
);

export const userSessionLogout = createAsyncThunk(
  "userSessionLogout",
  async ({ callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.USER_LOGOUT, {}, { signature, nonce });
      console.log("response", response)
      if (response?.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message);
      }
      toast.success(response?.message);
      callBack && callBack("", response);
      return response;
    } catch (error) {
      console.log("error", JSON.stringify(error?.message))

      // toast.error(error?.message || "Something went wrong!");

      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const changePassword = createAsyncThunk(
  "changePassword",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.CHANGE_PASSWORD, data, { signature, nonce });
      if (response.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message);
      }
      toast.success(response?.message);
      callBack && callBack("", response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");

      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const exportUsersList = createAsyncThunk(
  "exportUsersList",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let url = URL.EXPORT_USERS_LIST;

      if (data?.startDate && data?.endDate) {
        url = url + `/?startDate=${data.startDate}&endDate=${data.endDate}`
      }
      let response = await get(url, { signature, nonce });
      if (response.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message);
      }
      toast.success(response?.message);
      callBack && callBack("", response);
      // if (response?.data) {
      //   window.open(response?.data, "_blank");
      // }
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");

      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error);
    }
  }
);

export const { logoutUser } = slice.actions;

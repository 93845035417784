import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })


const Columns = ({ handleTaskStatus, handleEditHandler, deleteHandler, handleOrderUpdate, permissions }) => [
  {
    dataField: "title",
    text: "Title",
    formatter: (_, row) => <span className="text-capitalize">{row.title}</span>,
  },
  {
    dataField: "taskID",
    text: "Task ID",
    formatter: (_, row) => <span className="text-capitalize">{row.taskID}</span>,
  },
  {
    dataField: "order",
    text: "Order",
    formatter: (_, row) => <span className="text-capitalize">
      <input
        defaultValue={row?.order}
        type="number"
        style={{ width: 100, height: 40 }}
        onBlur={(e) => handleOrderUpdate(row, e.target.value)}
      />
    </span>,
  },
  {
    dataField: "icon",
    text: "Icon",
    formatter: (_, row) => <span className="text-capitalize">{
      row?.icon && <img src={row?.icon} width={30} height={30} />
    }</span>,
  },
  {
    dataField: "coins",
    text: "Coins",
    formatter: (_, row) => <span className="text">{row.coins || 0}</span>,
  },
  {
    dataField: "type",
    text: "Type",
    formatter: (_, row) => <span className="text">{row?.type}</span>,
  },
  {
    text: "Created At",
    dataField: "date_created_utc",
    formatter: (_, row) => row.date_created_utc && DATE_FORMAT(row.date_created_utc),
  },
  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
        {permissions?.edit ?
          row?.status && (row?.status === "active") ? (
            <button
              className="fas fa-toggle-on"
              style={{
                fontSize: "20px",
                color: "green",
                backgroundColor: "#fff",
                border: "none",
              }}
              title="Active"
              onClick={() => handleTaskStatus(row, "inactive")}
            ></button>
          ) : (
            <button
              className="fas fa-toggle-off"
              style={{
                fontSize: "20px",
                color: "red",
                backgroundColor: "#fff",
                border: "none",
              }}
              title="Inactive"
              onClick={() => handleTaskStatus(row, "active")}
            ></button>
          ) : null}
        {permissions?.edit ?
          <button
            className="fas fa-pen"
            style={{
              fontSize: "16px",
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Edit"
            onClick={() =>
              handleEditHandler(row)
            }
          ></button>
          : null}
        {permissions?.delete ?
          <button
            className="fas fa-trash-alt"
            style={{
              backgroundColor: "#fff",
              border: "none",
            }}
            title="Inactive"
            onClick={() => deleteHandler(row._id)}
          ></button>
          : null}
      </>
    ),
  },
];
export default Columns;

//dashboard
export const dashboard = `dashboard`

//settings
export const general_setting = `general-setting`
export const game_setting = `game-setting`
export const leaderboard_setting = `leaderboard-setting`
export const energy_setting = `energy-setting`
export const level_setting = `level-setting`
export const maintenance_setting = `maintenance-setting`
// //users
export const users = `users`
export const usersView = `users/view/:id`;
export const profile = `profile`
export const changePassword = `change-password`
export const login = `login`
export const forgot_password = `forgot_password`

//Language
export const language = `language/list`;

//game
export const gameHisory = `game-history`;
export const gameHisoryView = `game-history/view/:id`;
export const gameLeaderboard = `game-leaderboard`;
export const claimHistory = `claim-history`;
export const tasksList = `tasks`;
export const roadmapsList = `roadmaps`;
export const statistics = "statistics";
export const logs = "logs";
export const subadmins = "sub-admins";
export const subAdminView = `subadmin/view/:id`;
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "components/NotFound";
import AuthTheme from "theme/Auth";
import PrivateTheme from "theme/Private";
import DashboardTheme from "theme/SubTheme/dashboard";
import AdminRoute from "./Routes";
import { useDispatch, useSelector } from "react-redux";
import { getCoinsListApi, getSettings, viewUser } from "store/actions";
import { areCookiesEnabled, checkPermissions } from "helpers/common";
import { adminPermissions } from "constants";
function Routs() {
  const { user } = useSelector((state) => state.Auth);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
    areCookiesEnabled();
  }, []);

  useEffect(() => {
    if (user?._id) {
      dispatch(getCoinsListApi({}));
    }
  }, [user])

  return (
    <Routes>
      <Route element={<AuthTheme />}>
        <Route index element={<Navigate to="/login" />} />
        {AdminRoute.map((data, key) => (
          <Route key={key} path={data.path} element={data.component} />
        ))}
      </Route>

      <Route path="/admin/" element={<DashboardTheme />}>
        <Route index element={<Navigate to="/admin/users" />} />
        {AdminRoute.map((data, key) => (
          <Route
            key={key}
            path={data.path}
            element={<ProtectedRoute permission={data.permission} is_permission={data.is_permission} >{data.component}</ProtectedRoute>}
          />
        ))}
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Routs;

export const ProtectedRoute = ({ permission, is_permission, children }) => {
  const { user } = useSelector(({ Auth }) => Auth);
  const dispatch = useDispatch();

  const [authChecked, setAuthChecked] = useState(false); // To track if auth check is done

  useEffect(() => {
    const checkAuth = async () => {
      try {
        await dispatch(viewUser({})); // Dispatch the action to check authentication
      } catch (error) {
        console.error("Error checking auth:", error);
      } finally {
        setAuthChecked(true); // Mark auth check as completed
      }
    };

    checkAuth();
  }, [dispatch]);


  // While checking auth, show a loading indicator
  if (!authChecked) {
    return <p>Loading...</p>;
  }

  let userPermissions = [];

  if (user?._id) {
    if (user?.role === "ADMIN") {
      userPermissions = adminPermissions;
    }

    if (user?.role === "SUBADMIN") {
      userPermissions = user?.permissions?.length ? [...user?.permissions] : [];
    }

    if (!checkPermissions(permission, userPermissions) && is_permission) return <Navigate to="/login" />;

    // If authenticated, render the child component(protected content)

    return children;
  }

  // If not authenticated, redirect to the login page
  return <Navigate to="/login" />;
};

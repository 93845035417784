import { DATE_FORMAT } from "helpers/common";
import Badge from "react-bootstrap/Badge";
import React from "react";
import { Link } from "react-router-dom";
// import UnControll

// export const selectRow = (props) => ({
//   mode: "checkbox",
//   clickToSelect: true,
//   selectionHeaderRenderer: ({ indeterminate, mode, ...rest }) => (
//     <div className="custom-control custom-checkbox">
//       <input
//         type="checkbox"
//         className="custom-control-input"
//         ref={(input) => {
//           if (input) input.indeterminate = indeterminate
//         }}
//         {...rest}
//       />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   selectionRenderer: ({ mode, rowKey, ...rest }) => (
//     <div className="custom-control custom-checkbox" key={rowKey}>
//       <input type="checkbox" className="custom-control-input" {...rest} />
//       <label className="custom-control-label">&nbsp;</label>
//     </div>
//   ),
//   ...props,
// })

const STATUS = {
  "active": "Active",
  "inactive": "Inactive",
  "blocked": "Blocked",
  "deleted": "Deleted"
}

const Columns = ({ handleUserStatus, handleEditHandler, permissions }) => [
  {
    dataField: "first_name",
    text: "First Name",
    formatter: (_, row) => <span className="text-capitalize">{row.first_name}</span>,
  },
  {
    dataField: "last_name",
    text: "Last Name",
    formatter: (_, row) => <span className="text-capitalize">{row.last_name}</span>,
  },
  {
    dataField: "username",
    text: "ID",
    formatter: (_, row) => <span className="text">{row.username}</span>,
  },
  {
    dataField: "telegram_username",
    text: "Telegram username",
    formatter: (_, row) => <span className="text">{row.telegram_username ? row?.telegram_username : row.first_name}</span>,
  },
  {
    dataField: "energyBalance",
    text: "Energy Balance",
    formatter: (_, row) => <span className="text">{row?.energyBalance || 0}</span>,
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },
  {
    dataField: "btncBalance",
    text: "BTNC Balance",
    formatter: (_, row) => <span className="text-capitalize">{row?.btncBalance || 0}</span>,
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },
  {
    text: "Created At",
    dataField: "createdAt",
    formatter: (_, row) => row.createdAt && DATE_FORMAT(row.createdAt),
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },
  {
    text: "Last Login At",
    dataField: "lastLoginAt",
    formatter: (_, row) => row.lastLoginAt && DATE_FORMAT(row.lastLoginAt),
    sort: true,
    sortCaret: (order, column) => {
      if (!order) return (<span>&nbsp;&nbsp;▲▼</span>); // Default (no sort)
      else if (order === 'asc') return (<span>&nbsp;&nbsp;▲</span>); // Ascending
      else if (order === 'desc') return (<span>&nbsp;&nbsp;▼</span>); // Descending
      return null;
    },
  },
  {
    text: "Status",
    dataField: "status",
    formatter: (cellContent, row) => (
      <Badge
        className={
          `text-capitalize font-size-13 badge-soft-${row.status}`
        }
        color={row.badgeClass}
      >
        {(STATUS[row.status])}
      </Badge>
    ),
  },
  {
    text: "Action",
    dataField: "status",
    formatter: (_, row) => (
      <>
        {permissions?.edit ?
          row?.status && (row?.status === "active") ? (
            <button
              className="fas fa-toggle-on"
              style={{
                fontSize: "20px",
                color: "green",
                backgroundColor: "#fff",
                border: "none",
              }}
              title="Active"
              onClick={() => handleUserStatus(row._id, "blocked")}
            ></button>
          ) : (
            <button
              className="fas fa-toggle-off"
              style={{
                fontSize: "20px",
                color: "red",
                backgroundColor: "#fff",
                border: "none",
              }}
              title="Blocked"
              onClick={() => handleUserStatus(row._id, "active")}
            ></button>
          ) : null}

        <button
          className="fas fa-eye"
          style={{
            fontSize: "16px",
            backgroundColor: "#fff",
            border: "none",
          }}
          title="Edit"
          onClick={() =>
            handleEditHandler(row)
          }
        ></button>
      </>
    ),
  },
];
export default Columns;

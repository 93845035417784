import { createSlice, current } from "@reduxjs/toolkit";
import * as Act from "./actions";


const initialState = {
  loading: false,
  tasksList: [],
  totalTasks: 0,
  logsList: [],
  totalLogs: 0
};

const slice = createSlice({
  name: "tasks",
  initialState: { ...initialState },
  reducers: {

  },
  extraReducers: (builder) => {
    builder.addCase(Act.tasksListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.tasksListApi.fulfilled, (state, action) => {
      state.tasksList = action.payload.data;
      state.totalTasks = action.payload.totalcount;
      state.loading = false;
    });
    builder.addCase(Act.tasksListApi.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.addTaskApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.addTaskApi.fulfilled, (state, action) => {
      console.log("action.payload", action.payload)
      state.tasksList = [action.payload.data, ...state.tasksList];
      state.loading = false;
      state.totalTasks = state.totalTasks + 1
    });
    builder.addCase(Act.addTaskApi.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.updateTaskApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.updateTaskApi.fulfilled, (state, action) => {
      state.tasksList = state.tasksList.map((item) => item?._id === action.payload.data?._id ? ({ ...item, ...action.payload.data }) : item)
      state.loading = false;
    });
    builder.addCase(Act.updateTaskApi.rejected, (state, action) => {
      state.loading = false;

    });
    builder.addCase(Act.deleteTask.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.deleteTask.fulfilled, (state, action) => {
      state.tasksList = state.tasksList.filter((item) => item?._id !== action.payload.data?._id);
      state.totalTasks = state.totalTasks - 1;
      state.loading = false;
    });
    builder.addCase(Act.deleteTask.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(Act.logsListApi.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Act.logsListApi.fulfilled, (state, action) => {
      state.logsList = action.payload.data;
      state.totalLogs = action.payload.totalcount;
      state.loading = false;
    });
    builder.addCase(Act.logsListApi.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default slice;

import { createAsyncThunk } from "@reduxjs/toolkit"
import { post, get, put, updateCSRFToken } from "helpers/api_helper"
import { decrypt, getValuesSocketData } from "helpers/secretManager"
import * as URL from "helpers/url_helper"
import { toast } from "react-toastify"

export const gameHistoryListApi = createAsyncThunk(
  "gameHistoryListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.GAME_HISTORY_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const gameHistoryDetailsApi = createAsyncThunk(
  "gameHistoryDetailsApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await get(URL.GAME_HISTORY_DETAILS + "/" + data?._id, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)

      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const gameLeaderboardListApi = createAsyncThunk(
  "gameLeaderboardListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await post(URL.GAME_LEADERBOARD_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)

export const updateLeaderboardApi = createAsyncThunk(
  "updateLeaderboardApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let response = await put(URL.UPDATE_LEADERBOARD + data?._id, { points: data?.points }, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  })

export const ClaimHistoryListApi = createAsyncThunk(
  "ClaimHistoryListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.CLAIM_HISTORY_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)



export const EnergyHistoryListApi = createAsyncThunk(
  "EnergyHistoryListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.ENERGY_HISTORY_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)


export const getCoinsListApi = createAsyncThunk(
  "getCoinsListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await get(URL.COINS_LIST, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)



export const exportGameList = createAsyncThunk(
  "exportGameList",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let url = URL.EXPORT_GAME_LIST;

      if (data?.startDate && data?.endDate) {
        url = url + `/?startDate=${data.startDate}&endDate=${data.endDate}`
      }
      let response = await get(url, { signature, nonce })
      if (response.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message);
      // if (response?.data) {
      //   window.open(response?.data, '_blank');
      // }
      callBack && callBack("", response);
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");

      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error)
    }
  }
)


export const exportClaimList = createAsyncThunk(
  "exportClaimList",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();
      let url = URL.EXPORT_CLAIM_LIST;
      if (data?.startDate && data?.endDate) {
        url = url + `/?startDate=${data.startDate}&endDate=${data.endDate}`
      }
      let response = await get(url, { signature, nonce })
      if (response.status === "failed") {
        toast.error(response?.message);
        callBack && callBack(response?.message, "");
        return Thunk.rejectWithValue(response?.message)
      }
      toast.success(response?.message);
      callBack && callBack("", response);
      // if (response?.data) {
      //   window.open(response?.data, '_blank');
      // }
      return response;
    } catch (error) {
      toast.error(error?.message || "Something went wrong!");

      callBack && callBack(error?.message || "Something went wrong!", "");
      return Thunk.rejectWithValue(error)
    }
  }
)


export const BtncHistoryListApi = createAsyncThunk(
  "BtncHistoryListApi",
  async ({ data, callBack }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.BTNC_HISTORY_LIST, data, { signature, nonce })
      if (response.status === "failure") {
        toast.error(response?.message)
        return Thunk.rejectWithValue(response?.message)
      }
      callBack && callBack(null, response)
      return response;
    }
    catch (error) {
      console.log(error, "<===error")
      toast.error(error?.message || "Something went wrong!")
      callBack && callBack(error?.message || "Something went wrong!")

      return Thunk.rejectWithValue(error)
    }
  }
)



export const getUserTasks = createAsyncThunk(
  "getUserTasks",
  async ({ data, callback }, Thunk) => {
    try {
      const { signature, nonce } = await getValuesSocketData();

      let response = await post(URL.USER_TASKS_LIST, data, { signature, nonce });
      callback && callback(null, response.data);
      return response;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTasks } from "store/actions";
import Table from "components/Table";
import Columns from "./Columns";


const InputFieldsForSearch = [
    {
        name: "search",
        type: "text",
        label: "",
        placeholder: "Search...",
    }
];
const initialValuesForFilter = {
    search: "",
};



const List = ({ userDetails }) => {
    const dispatch = useDispatch();
    const { userTasksList, loading, totalUserTasks } = useSelector(({ Game }) => Game);
    const [columns, setcolumns] = useState([{ dataField: "", text: "" }]);

    const [filter, setFilter] = useState({
        page: 1,
        limit: 20,
        order: -1,
        orderBy: "date_created_utc",
        search: "",
        userId: userDetails?._id
    });

    useEffect(() => {
        if (userDetails?._id) {

            getApiData({ ...filter, userId: userDetails?._id });
            setcolumns(Columns({ handleEditHandler }));
        }
    }, [filter, userDetails]);

    async function getApiData(query) {
        try {
            dispatch(getUserTasks({ data: query }));
        } catch (error) { }
    }

    const handleEditHandler = (info) => {

    };


    const handleFilterForm = (info) => {
        setFilter({
            ...filter,
            page: 1,
            limit: 20,
            order: -1,
            orderBy: "date_created_utc",
            search: info.search
        });
    };


    return (
        <>
            <div className="section-body">
                {/* <div className="card">
          <div className="row justify-content-between">
            <div className="col-12">
              <div className="card-header">
                <FormikForm
                  initialValues={initialValuesForFilter}
                  onSubmit={handleFilterForm}
                  loading={loading}
                  inputFields={InputFieldsForSearch}
                  buttonText={"Search"}
                />
              </div>
            </div>

          </div>
        </div> */}
                {userTasksList?.length > 0 && (
                    <Table
                        data={userTasksList}
                        columns={columns}
                        setFilter={setFilter}
                        total={totalUserTasks}
                        filter={filter}
                    />
                )}
                {!loading && userTasksList?.length === 0 && (
                    <p style={{ textAlign: "center" }}>No Data Found</p>
                )}
            </div>
        </>
    );
};

export default List;

import { DATE_FORMAT } from "helpers/common";
import React from "react";


const Columns = ({ }) => [
  {
    dataField: "From Username",
    text: "Username",
    formatter: (_, row) => <span className="text-capitalize">{row?.fromUsername ? row?.fromUsername : row?.fromFirstName || "---"}</span>,
  },

  {
    dataField: "From WalletId",
    text: "WalletId",
    formatter: (_, row) => <span className="text-capitalize">{row?.fromWalletId}</span>,
  },
  {
    dataField: "btncAmount",
    text: "Earnings",
    formatter: (_, row) => <span className="text">{row?.btncAmount}</span>,
  },
  {
    dataField: "prevBtncBalance",
    text: "Pre BTNC",
    formatter: (_, row) => <span className="text">{row?.prevBtncBalance}</span>,
  },
  {
    dataField: "newBtncBalance",
    text: "New BTNC",
    formatter: (_, row) => <span className="text-capitalize">{row?.newBtncBalance}</span>,
  },
  {
    dataField: "date_created_utc",
    text: "created At",
    formatter: (_, row) => <span className="text-capitalize">{row?.date_created_utc && DATE_FORMAT(row?.date_created_utc)}</span>,
  }
];
export default Columns;
